import { InfoAlert } from "Components/Alerts";
import Loader from "Components/Common/Loader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import DateDisplay from "Components/Displays/DateDisplay";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import Weight from "Components/Displays/UnitDisplay/Weight";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardTitle, Modal, ModalBody, ModalHeader, Table, UncontrolledTooltip } from "reactstrap";
import CreateShipmentForm from "./_CreateShipmentForm";
import Restricted from "Components/Common/Restricted";
import _ from "lodash";
import Currency from "Components/Displays/UnitDisplay/Currency";
import { createAppSelector, useAppSelector } from "Components/Hooks/StoreHooks";
import BusyOverlay from "Components/Common/BusyOverlay";
import { postPrintDocument } from "api/printing";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { multiDownload, withCdn } from "helpers/urlHelper";
import PrivateDownload from "Components/Common/PrivateDownload";
import { FbaShipmentStatus } from "api/types/contracts/shipping";

type ShipmentListProps = {
    
}

const ShipmentList = (props: ShipmentListProps) => {
    const { t } = useTranslation();
    const [createModal, setCreateModal] = useState(false);
    const toggleCreateModal = () => setCreateModal(prev => !prev);

    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping], 
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );

    const printDocumentMutation = useMutation({
        mutationKey: ["print-document"],
        mutationFn: postPrintDocument,
        onSuccess: result => {
            if (result.printQueued) {
                toast.success(t("Document sent to printer"));
            }
            else {
                multiDownload([withCdn(result.filePath)]);
            }
        }
    })

    const printCommercialInvoice = (path: string) => {
        printDocumentMutation.mutate({ path, templateType: "shippingLabel" });
    }

    const printShippingLabel = (path: string) => {
        printDocumentMutation.mutate({ path, templateType: "shippingLabel" });
    }

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    const list = fbaShipment.packages.filter(p => p.transport);

    return <>
        {Array<FbaShipmentStatus>("boxLabelPending", "boxLabelUploaded").includes(fbaShipment.status) && <>
            <Restricted require="shipping.fbashipping" write>
                <div className="d-flex justify-content-center mb-3">
                    <Button color="primary" onClick={toggleCreateModal}>{t("Create Shipments")}</Button>
                </div>
            </Restricted>
        </>}
        <Card>
            <CardHeader>
                <CardTitle tag="h5" className="mb-0">{t("Shipments")}</CardTitle>
            </CardHeader>
            <CardBody>
                {list.length > 0 ? <>
                    <div className="table-responsive table-card">
                        <Table className="mb-0 align-middle">
                            <thead className="table-light text-muted">
                                <tr>
                                    <th>{t("Actions")}</th>
                                    <th>{t("Created Date")}</th>
                                    <th>{t("Dimensions")}</th>
                                    <th>{t("Weight")}</th>
                                    <th>{t("Total Items")}</th>
                                    <th>{t("SKU Count")}</th>
                                    <th>{t("Carrier")}</th>
                                    <th>{t("Track ID")}</th>
                                    <th>{t("Price")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((item, index) => <tr key={index}>
                                    <td>
                                    <div className="hstack gap-1">
                                        <BusyOverlay busy={printDocumentMutation.isPending} size="sm" inline>
                                            <Button color="soft-primary" className="btn-icon" size="sm" 
                                                id={`print-shipping-label-${index}`} 
                                                onClick={() => printShippingLabel(item.transport!.labelPath)}>
                                                <i className="ri-printer-fill"></i>
                                            </Button>
                                        </BusyOverlay>
                                        <UncontrolledTooltip target={`print-shipping-label-${index}`}>
                                            {t("Print Shipping Label")}
                                        </UncontrolledTooltip>
                                        {item.transport!.commercialInvoicePath ? <BusyOverlay busy={printDocumentMutation.isPending} size="sm" inline>
                                            <Button size="sm" color="soft-danger" 
                                                id={`print-commercial-invoice-${index}`}
                                                onClick={() => printCommercialInvoice(item.transport!.commercialInvoicePath!)} className="btn-icon">
                                                <i className="ri-file-text-fill"></i>
                                            </Button>
                                        </BusyOverlay> : <Button color="soft-danger" size="sm" 
                                            id={`print-commercial-invoice-${index}`}
                                            disabled className="btn-icon">
                                            <i className="ri-file-text-fill"></i>
                                        </Button>}
                                        <UncontrolledTooltip target={`print-commercial-invoice-${index}`}>
                                            {t("Print Commercial Invoice")}
                                        </UncontrolledTooltip>
                                        <PrivateDownload href={item.transport!.labelPath}>
                                            {(labelPath) => <>
                                            <a href={labelPath} rel="noreferrer" target="_blank" 
                                                id={`download-shipping-label-${index}`}
                                                className="btn btn-sm btn-soft-success btn-icon">
                                                <i className="bx bx-package"></i>
                                            </a>
                                            <UncontrolledTooltip target={`download-shipping-label-${index}`}>
                                                {t("Download Shipping Label")}
                                            </UncontrolledTooltip>
                                        </>}
                                        </PrivateDownload>
                                    </div>
                                    </td>
                                    <td>
                                        <DateDisplay date={item.transport!.createdAt} />
                                    </td>
                                    <td>
                                        <Dimensions value={item.box} unit={item.box.lengthUnit} />
                                    </td>
                                    <td>
                                        <Weight value={item.box.weight} unit={item.box.weightUnit} />
                                    </td>
                                    <td>
                                        {_.sumBy(item.box.contents, p => p.sellableQuantity + p.unusableQuantity)}
                                    </td>
                                    <td>
                                        {item.box.contents.length}
                                    </td>
                                    <td>
                                        {item.transport!.carrierAccountService.carrierService.carrier.name}
                                    </td>
                                    <td>
                                        {item.transport!.trackingNumber}
                                    </td>
                                    <td>
                                        <Currency value={item.transport!.totalCharge} currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </> : <>
                    <InfoAlert className="mb-0">
                        {t("No shipments found")}
                    </InfoAlert>
                </>}
            </CardBody>
        </Card>
        <Modal backdrop="static" size="xl" isOpen={createModal} toggle={toggleCreateModal} unmountOnClose>
            <ModalCloseButton onClick={toggleCreateModal} />
            <ModalHeader>
                {t("Create Shipment")}
            </ModalHeader>
            <ModalBody>
                <CreateShipmentForm onSuccess={toggleCreateModal} />
            </ModalBody>
        </Modal>
    </>;
}

export default ShipmentList;
import { BinContract } from "api/types/contracts/bin";
import { ProductLabelContract, type BoxContract } from "api/types/contracts/inventory";
import { BinLocationContract } from "api/types/contracts/locations";

function locationOf(item: ProductLabelContract | BinLocationContract | BinContract | BoxContract | undefined): string
{
    if (!item) {
        return "";
    }

    if ("productLabelId" in item || "boxId" in item)  {
        return item.location ? (item.location.binTarget && `#${item.location.binTarget.binCode}`) ?? (item.location.binLocationTarget && `${item.location.binLocationTarget.name}`) ?? "" : "";
    }
    else if ("binId" in item) {
        return item.binLocation ? `${item.binLocation?.locationLabel ?? ""}-${item.binLocation?.name ?? ""}` : "";
    }
    else {
        return `${item.locationLabel ?? ""}`;
    }
}

export { locationOf };
import { UnitOfCurrency } from "api/types/contracts/common";
import { createAppSelector, useAppSelector } from "./StoreHooks";
import _ from "lodash";

const useCommon = () => {
    const { exchangeRates } = useAppSelector(
        createAppSelector([state => state.Common], 
            (common) => ({
                exchangeRates: common.usdExchangeRates
            })
        )
    );

    const calculateExchange = (amount: number | undefined, from: UnitOfCurrency, to: UnitOfCurrency) => {
        if (amount === undefined) {
            return 0;
        }

        if (to === from) {
            return amount;
        }

        return _.round(amount / exchangeRates[from] * exchangeRates[to], 2);
    }

    return { calculateExchange };
};

export { useCommon };

import { APIClient } from "../helpers/api_helper";
import type { GenerateBinOrBoxLabelCommand, GenerateFnskuLabelCommand, GenerateLocationLabelCommand, GenerateFbaShipmentLabelCommand, PrintDocumentCommand, PrintTestPageCommand, SavePrinterPreferenceCommand, SavePrintPreferencesCommand, UpdateClientPrinterCommand } from "./types/commands";
import { PrintingSetupContract, PrintPreferenceContract, PrintSizeContract, type ClientPrinterContract, type PrintResultContract } from "./types/contracts/printing";
import type { GetPrinterPreferencesQuery, GetPrintPreferenceQuery, ListClientPrintersQuery, ListPrintSizesQuery } from "./types/queries";

const api = new APIClient();

export const getPrinterList = async (data: ListClientPrintersQuery) => {
    return await api.get(`/api/print/printer`, data) as ClientPrinterContract[];
}

export const postTestPrint = async (data: PrintTestPageCommand) => {
    return await api.post(`/api/print/test`, data);
}

export const getPrinterPreferences = async (data: GetPrinterPreferencesQuery) => {
    return await api.get<PrintingSetupContract>(`/api/print/preferences`, data);
}

export const getPrintSizeList = async (data: ListPrintSizesQuery) => {
    return await api.get<PrintSizeContract[]>(`/api/print/size`, data);
}

export const patchPrintPreferences = async (data: SavePrintPreferencesCommand) => {
    await api.patch(`/api/print/preference/${data.printPreferenceId}`, { ...data });
}

export const patchPrinterPreferences = async (data: SavePrinterPreferenceCommand) => {
    await api.patch(`/api/print/preference/${data.printPreferenceId}/printer`, { ...data });
}

export const getPrintPreference = async (data: GetPrintPreferenceQuery) => {
    return await api.get<PrintPreferenceContract>(`/api/print/preference/${data.printPreferenceId}`);
}

export const patchClientPrinter = async (data: UpdateClientPrinterCommand) => {
    await api.patch(`/api/print/printer/${data.clientPrinterId}`, { ...data });
}

export const postGenerateBinLabel = async (data: GenerateBinOrBoxLabelCommand) => {
    const result = await api.post(`/api/print/document/binLabel/${data.binId}`, {  });

    return result.data as PrintResultContract;
}

export const postGenerateBoxLabel = async (data: GenerateBinOrBoxLabelCommand) => {
    const result = await api.post(`/api/print/document/boxLabel/${data.boxId}`, {  });

    return result.data as PrintResultContract;
}

export const postGenerateAisleLabel = async (data: GenerateLocationLabelCommand) => {
    const result = await api.post(`/api/print/document/locationLabel/aisle/${data.aisleId}`, {  });

    return result.data as PrintResultContract;
}

export const postGenerateBayLabel = async (data: GenerateLocationLabelCommand) => {
    const result = await api.post(`/api/print/document/locationLabel/bay/${data.bayId}`, {  });

    return result.data as PrintResultContract;
}

export const postGenerateShelfLabel = async (data: GenerateLocationLabelCommand) => {
    const result = await api.post(`/api/print/document/locationLabel/shelf/${data.shelfId}`, {  });

    return result.data as PrintResultContract;
}

export const postGenerateBinLocationLabel = async (data: GenerateLocationLabelCommand) => {
    const result = await api.post(`/api/print/document/locationLabel/binLocation/${data.binLocationId}`, {  });

    return result.data as PrintResultContract;
}

export const postGenerateFnskuLabel = async (data: GenerateFnskuLabelCommand) => {
    const { fbaShipmentItemId, ...rest } = data;
    const result = await api.post(`/api/print/document/fnskuLabel/${data.fbaShipmentItemId}`, rest);

    return result.data as PrintResultContract;
}

export const postGenerateFbaShipmentLabel = async (data: GenerateFbaShipmentLabelCommand) => {
    const result = await api.post(`/api/print/document/fbaShipmentLabel/${data.shipmentId}`, {  });

    return result.data as PrintResultContract;
}

export const postPrintDocument = async (data: PrintDocumentCommand) => {
    const result = await api.post(`/api/print/document`, { ...data });

    return result.data as PrintResultContract;
}
import { useMutation } from "@tanstack/react-query";
import { postGenerateFbaShipmentLabel } from "api/printing";
import BusyOverlay from "Components/Common/BusyOverlay";
import Loader from "Components/Common/Loader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import AddressDisplay from "Components/Displays/AddressDisplay";
import BalanceDisplay from "Components/Displays/BalanceDisplay";
import CountryDisplay from "Components/Displays/CountryDisplay";
import FbaShipmentStatusBadge from "Components/Displays/FbaShipmentStatusBadge";
import Currency from "Components/Displays/UnitDisplay/Currency";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { createAppSelector, useAppSelector } from "Components/Hooks/StoreHooks";
import { multiDownload, withCdn } from "helpers/urlHelper";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Modal, ModalBody } from "reactstrap";

type TopInfoProps = {
    
}

const TopInfo = (props: TopInfoProps) => {
    const { t } = useTranslation();
    const { userProfile } = useProfile();
    
    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping],
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );

    const printShipmentLabelMutation = useMutation({
        mutationFn: postGenerateFbaShipmentLabel,
        onSuccess: (data, variables, context) => {
            if (data.printQueued) {
                toast.success(t("Shipment label has been queued for printing"));
            }
            else {
                multiDownload([withCdn(data.filePath)]);
            }
        }
    });

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    const isOwner = fbaShipment.owner.userId === userProfile?.user.userId;

    return <>
        <div className="hstack align-items-stretch mb-3 gap-3">
            <div style={{ flex: 2 }}>
                <Card body className="mb-0 h-100">
                    <dl className="row align-items-center mb-0 g-2">
                        <dt className="col-6">{t("ID")}</dt>
                        <dd className="col-6 mb-0">
                            <span># {fbaShipment.shipmentCode}</span>
                            <BusyOverlay busy={printShipmentLabelMutation.isPending} size="sm" inline backgroundColor="body-secondary" spinnerColor="tenant-primary" opaque>
                                <Button color="print" size="sm" className="ms-1 link-info py-0" onClick={() => {
                                    printShipmentLabelMutation.mutate({
                                        shipmentId: fbaShipment.fbaShipmentId
                                    });
                                }}>
                                    <i className="ri-printer-fill fs-14"></i>
                                </Button>
                            </BusyOverlay>
                            </dd>
                        <dt className="col-6">{t("Status")}</dt>
                        <dd className="col-6 mb-0"><FbaShipmentStatusBadge value={fbaShipment.status} /></dd>
                        <dt className="col-6">{t("Country")}</dt>
                        <dd className="col-6 mb-0"><CountryDisplay country={fbaShipment.shipmentTarget.address.country} /></dd>
                        <dt className="col-6">{t("W/T Products")}</dt>
                        <dd className="col-6 mb-0">{_.sumBy(fbaShipment.items, "count")}/{0}</dd>
                        <dt className="col-6">{t("Selected Carrier")}</dt>
                        <dd className="col-6 mb-0">{fbaShipment.carrierAccountService?.carrierService.name ?? "Provided By Customer"}</dd>
                        <dt className="col-6">{t("Expected Total Price")}</dt>
                        <dd className="col-6 mb-0"><Currency value={fbaShipment.actualCost.total} currency={fbaShipment.warehouse.settings.unitOfCurrency} /></dd>
                    </dl>
                </Card>
            </div>
            <div style={{ flex: 2 }}>
                <Card body className="mb-0 h-100">
                    <dl className="row align-items-center mb-0 g-2">
                        <dt className="col-6">{t("Customer Suite")}</dt>
                        <dd className="col-6 mb-0"># {fbaShipment.customer.suitNumber}</dd>
                        <dt className="col-6">{t("Name")}</dt>
                        <dd className="col-6 mb-0">{fbaShipment.customer.user.name}</dd>
                        <dt className="col-6">{t("Email")}</dt>
                        <dd className="col-6 mb-0">{fbaShipment.customer.user.email}</dd>
                        <dt className="col-6">{t("Phone")}</dt>
                        <dd className="col-6 mb-0">{fbaShipment.customer.phone}</dd>
                        {!isOwner && <>
                            <dt className="col-6">{t("Current Balance")}</dt>
                            <dd className="col-6 mb-0">
                                <BalanceDisplay customer={fbaShipment.customer} warehouseId={fbaShipment.warehouse.warehouseId} />
                            </dd>
                        </>}
                    </dl>
                </Card>
            </div>
            {isOwner && <div style={{ flex: 1 }}>
                <Card className="mb-0 h-100">
                    <CardBody>
                        <AddressDisplay 
                            className="fs-12 h-100 justify-content-between"
                            copyButtonClassName="z-1"
                            address={fbaShipment.warehouse.address} 
                            suitNumber={fbaShipment.customer.suitNumber}
                            warehouseName={fbaShipment.warehouse.name}
                            shipmentCode={fbaShipment.shipmentCode} />
                    </CardBody>
                </Card>
            </div>}
        </div>
    </>;
}

export default TopInfo;
import { FbaShipmentStatus } from "api/types/contracts/shipping";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

const fbaShipmentStatusMap: Record<FbaShipmentStatus, { color: string }> = {
    "draft": { color: "soft-dark" },
    "productPending": { color: "warning" },
    "fnskuPending": { color: "soft-warning" },
    "ready": { color: "success" },
    "processing": { color: "soft-success" },
    "boxLabelPending": { color: "info" },
    "boxLabelUploaded": { color: "soft-primary" },
    "paymentWaiting": { color:"soft-info" },
    "paymentCompleted": { color: "secondary" },
    "shipped": { color: "primary" },
    "cancelled": { color: "danger" },
};

type StatusPillsProps = {
    value: FbaShipmentStatus | undefined,
    onChange: (value: FbaShipmentStatus | undefined) => void,
    statusCounts: Record<FbaShipmentStatus | "all", number>
};

const buttonClass = "rounded rounded-pill";

const StatusPills = (props: StatusPillsProps) => {
    const { t } = useTranslation();

    return <div className="hstack gap-2 flex-wrap">
        <Button color="dark" className={classNames(buttonClass)} size="sm" active={props.value === undefined} onClick={() => props.onChange(undefined)}>{t("All")} ({props.statusCounts.all})</Button>
        {Object.keys(fbaShipmentStatusMap).map((key) => {
            const status = key as FbaShipmentStatus;
            const { color } = fbaShipmentStatusMap[status];
            const active = status === props.value;

            return <Button className={classNames(buttonClass)} size="sm" key={status} active={active} color={active || !props.value ? color : "light"} onClick={() => props.onChange(status)}>
                {t(`enums:FbaShipmentStatus.${status}`)} ({props.statusCounts[status] ?? 0})
            </Button>
        })}
    </div>
}

export default StatusPills;
import classNames from "classnames";
import { type PropsWithChildren } from "react";

type LinedHeaderProps = PropsWithChildren<{
    tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6",
    after?: JSX.Element,
    align?: "start" | "center" | "end",
    className?: string,
    headerClassName?: string,
}>;

const LinedHeader = ({ tag, children, after, align, className, headerClassName }: LinedHeaderProps) => {
    const Header = tag || "h5";

    return <div className={classNames("hstack gap-3 align-items-center mb-2", className)}>
        {align === "end" || align === "center" ? <hr className="w-100" /> : null}
        <Header className={classNames("mb-0 flex-grow-1 text-nowrap", headerClassName)}>{children}</Header>
        {after}
        {!align || align === "start" || align === "center" ? <hr className="w-100" /> : null}
    </div>;
}

export default LinedHeader;
import { useQuery } from "@tanstack/react-query";
import { InfoAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import Dialog, { type DialogRef } from "Components/Common/Dialog";
import LinedHeader from "Components/Common/LinedHeader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import Currency from "Components/Displays/UnitDisplay/Currency";
import PrepServiceSelect from "Components/EntitySelects/PrepServiceSelect";
import NumberInput from "Components/Form/NumberInput";
import StepInput from "Components/Form/StepInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { getPrepServiceList } from "api/prepService";
import { UnitOfCurrency } from "api/types/contracts/common";
import { FbaShipmentCostContract, FbaShipmentCostTableItemContract, PrepServiceContract } from "api/types/contracts/shipping";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, Row, Table } from "reactstrap";
import * as Yup from "yup";

type ShipmentCostBreakdownViewProps = {
    value: FbaShipmentCostContract,
    canAdd?: boolean,
    canEdit?: boolean,
    warehouseId?: string,
    currency: UnitOfCurrency,
    onPrepServiceAdded: (prepServiceId: string) => void | Promise<void>,
    onPrepServiceRemoved: (prepServiceId: string) => void | Promise<void>,
    onChange: (value: FbaShipmentCostContract) => void | Promise<void>
}

const ShipmentCostBreakdownView = (props: ShipmentCostBreakdownViewProps) => {
    const { t } = useTranslation();
    const [busy, setBusy] = useState(false);
    const [prepServiceAddModal, setPrepServiceAddModal] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState<number>();
    const dialogRef = useRef<DialogRef>(null);
    const [selectedPrepServiceToAdd, setSelectedPrepServiceToAdd] = useState<PrepServiceContract>();

    const prepServicesQuery = useQuery({
        queryKey: ["prep-services-select"],
        queryFn: () => getPrepServiceList({
            page: 1,
            pageSize: 99
        }),
        select: data => data.items
    });

    const validation = useFormik({
        initialValues: {
            ...props.value,
            items: props.value.items.map(i => ({
                ...i
            }))
        },
        validationSchema: Yup.object<FbaShipmentCostContract, TypedShape<FbaShipmentCostContract>>({
            import: Yup.number().required().min(0),
            shipping: Yup.number().required().min(0),
            prep: Yup.number().required().min(0),
            insurance: Yup.number().required().min(0),
            oversize: Yup.number().required().min(0),
            overweight: Yup.number().required().min(0),
            total: Yup.number().required().min(0),
            items: Yup.array<FbaShipmentCostTableItemContract>(Yup.object<FbaShipmentCostTableItemContract, TypedShape<FbaShipmentCostTableItemContract>>({
                count: Yup.number().required().min(0),
                total: Yup.number().required().min(0),
                unitPrice: Yup.number().required().min(0),
                serviceName: Yup.string().required(),
                prepServiceId: Yup.string().required(),
                discount: Yup.number().notRequired().min(0)
            }).required()).required(),
        }),
        onSubmit: async values => {
            values.prep = _.sumBy(values.items, i => i.total);
            values.total = values.shipping + values.import + values.insurance + values.oversize + values.overweight + values.oversize + values.prep;

            await props.onChange(values);
        }
    })

    const prepServicesTotal = useMemo(() => _.sumBy(validation.values.items, i => i.total), [validation.values]);

    const togglePrepServiceAddModal = () => {
        setSelectedPrepServiceToAdd(undefined);
        setPrepServiceAddModal(prev => !prev);
    };

    const debouncedSave = useDebounce(() => validation.submitForm(), 1000, [validation.values]);

    const handleRemoveItemClick = (index: number) => {
        setSelectedItemIndex(index);
        dialogRef.current?.show();
    }

    const removeItem = async (index: number) => {
        const item = validation.values.items[index];

        if (item.deletable) {
            setBusy(true);

            await props.onPrepServiceRemoved(item.prepServiceId);
            setBusy(false);
        }
    }

    useEffect(() => {
        validation.setValues({
            ...props.value,
            items: props.value.items.map(i => ({
                ...i
            }))
        });
    }, [props.value]);

    const shippingTotal = validation.values.shipping + validation.values.import + validation.values.insurance + validation.values.oversize + validation.values.overweight;

    return <>
        <Card>
            <CardHeader className="border-0 pb-0">
                <h5 className="card-title mb-0">{t("Total Cost Breakdown")}</h5>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={6}>
                        {props.canAdd && <div className="d-flex justify-content-center align-items-center border-bottom mb-0 p-2">
                            <h6 className="mb-0">{t("FPA Prep Cost")}</h6>
                            <Button className="ms-auto" size="sm" color="secondary" type="button" onClick={togglePrepServiceAddModal}>{t("Add New Service")}</Button>
                        </div>}
                        {validation.values.items && validation.values.items.length > 0 ? <Table striped borderless className="mb-0 align-middle" size="sm">
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "250px"}}>{t("Service Name")}</th>
                                    <th style={{ minWidth: "100px"}}>{t("Unit Price")}</th>
                                    <th>{t("Count")}</th>
                                    <th>{t("Total")}</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {validation.values.items.map((costItem, i) => {
                                    const prepService = prepServicesQuery.isSuccess ? prepServicesQuery.data.find(p => p.prepServiceId === costItem.prepServiceId) : undefined;
                                    
                                    return <tr key={i}>
                                        <td>{prepService ? <>
                                            <span>{prepService.name}</span>
                                            <br />
                                            <small className="text-muted">{prepService.description}</small>
                                        </> : costItem.serviceName}</td>
                                        <td><Currency value={costItem.unitPrice} currency={props.currency} /></td>
                                        <td>
                                            {props.canEdit ? <ValidationWrapper validation={validation} field={`items.${i}.count`}>
                                                <StepInput min={1} size="sm" onChange={val => {
                                                    validation.setFieldValue(`items.${i}.total`, _.round((val ?? 0) * costItem.unitPrice, 2));
                                                    debouncedSave();
                                                }} />
                                            </ValidationWrapper> : costItem.count}
                                        </td>
                                        <td>
                                            {props.canEdit ? <ValidationWrapper validation={validation} field={`items.${i}.total`}>
                                                <CurrencyInput size="sm" onChange={() => debouncedSave()} />
                                            </ValidationWrapper> : <Currency value={costItem.total} currency={props.currency} />}
                                        </td>
                                        <td>
                                            <Button disabled={!costItem.deletable} color="ghost-danger" size="sm" className="btn-icon" onClick={() => handleRemoveItemClick(i)}>
                                                <i className="ri-delete-bin-2-line fs-16" />
                                            </Button>
                                        </td>
                                    </tr>;
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={3} className="text-end">{t("FBA Prep Total")}</th>
                                    <th>
                                        <Currency value={validation.values.prep} currency={props.currency} />
                                    </th>
                                </tr>
                            </tfoot>
                        </Table> : <>
                            <InfoAlert>{t("No Prep Services Selected")}</InfoAlert>
                        </>}
                    </Col>
                    <Col md={6}>
                        <LinedHeader tag="h6" className="p-2" align="center">{t("Shipping Cost")}</LinedHeader>
                        <Table borderless className="align-middle" size="sm">
                            <tbody>
                                <tr>
                                    <td>{t("Shipping Cost")}</td>
                                    <th className="w-25">
                                        <Currency value={validation.values.shipping} currency={props.currency} />
                                    </th>
                                </tr>
                                <tr>
                                    <td>{t("Import Duty & Customs Tax")}</td>
                                    <th>
                                        <Currency value={validation.values.import} currency={props.currency} />
                                    </th>
                                </tr>
                                <tr>
                                    <td>{t("Insurance Cost")}</td>
                                    <th>
                                        <Currency value={validation.values.insurance} currency={props.currency} />
                                    </th>
                                </tr>
                                <tr>
                                    <td>{t("Oversize Cost")}</td>
                                    <th>
                                        <Currency value={validation.values.oversize} currency={props.currency} />
                                    </th>
                                </tr>
                                <tr>
                                    <td>{t("Overweight Cost")}</td>
                                    <th>
                                        <Currency value={validation.values.overweight} currency={props.currency} />
                                    </th>
                                </tr>
                                <tr>
                                    <th>{t("Total")}</th>
                                    <th><Currency value={shippingTotal} currency={props.currency} /></th>
                                </tr>
                            </tbody>
                        </Table>
                        <LinedHeader tag="h6" className="p-2" align="center">{t("Summary")}</LinedHeader>
                        <Table borderless className="mb-0 align-middle" size="sm">
                            <tbody>
                                <tr>
                                    <td>{t("Shipping & Tax")}</td>
                                    <th className="w-25"><Currency value={shippingTotal} currency={props.currency} /></th>
                                </tr>
                                <tr>
                                    <td>{t("FBA Preps")}</td>
                                    <th><Currency value={prepServicesTotal} currency={props.currency} /></th>
                                </tr>
                                <tr>
                                    <th className="text-right">{t("Total")}</th>
                                    <th>{validation.values.shipping ? <Currency value={shippingTotal + prepServicesTotal} currency={props.currency} /> : "-"}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <Modal backdrop="static" isOpen={prepServiceAddModal} toggle={togglePrepServiceAddModal} size="sm">
            <ModalBody>
                <ModalCloseButton onClick={togglePrepServiceAddModal} />
                <div className="vstack gap-3">
                    <h5 className="mb-0">{t("Add Prep Service")}</h5>
                    <PrepServiceSelect channel="fba" value={selectedPrepServiceToAdd?.prepServiceId} warehouseId={props.warehouseId} onSelect={setSelectedPrepServiceToAdd} />
                    <BusyOverlay busy={busy} size="sm">
                        <Button color="primary" disabled={!selectedPrepServiceToAdd} className="w-100" onClick={async () => {
                            if (selectedPrepServiceToAdd) {
                                if (validation.values.items.find(i => i.prepServiceId === selectedPrepServiceToAdd.prepServiceId)) {
                                    togglePrepServiceAddModal();
                                    return;
                                }

                                setBusy(true);

                                await props.onPrepServiceAdded(selectedPrepServiceToAdd.prepServiceId);
                                togglePrepServiceAddModal();

                                setBusy(false);
                            }
                        }}>{t("Add Service")}</Button>
                    </BusyOverlay>
                </div>
            </ModalBody>
        </Modal>
        <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={busy} iconClass="ri-delete-bin-2-line"  
            message={`Do you want to continue?`} title={`Removing "${validation.values.items[selectedItemIndex ?? 0]?.serviceName ?? "Service"}"`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    removeItem(selectedItemIndex!).then(() => {
                        hide();
                    });
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default ShipmentCostBreakdownView;
import { useQuery } from "@tanstack/react-query";
import { getPrepServiceList } from "api/prepService";
import type { InvoiceItemForm } from "api/types/contracts/payment";
import SelectInput from "Components/Form/SelectInput";
import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const staticItems = [
    { group: "Shipping & Tax", title: "Shipping Cost", item: "Shipping Cost" },
    { group: "Shipping & Tax", title: "Import Tax", item: "Import Tax" },
    { group: "Shipping & Tax", title: "Insurance Fee", item: "Insurance Fee" },
    { group: "Shipping & Tax", title: "Oversize Fee", item: "Oversize Fee" },
    { group: "Shipping & Tax", title: "Overweight Fee", item: "Overweight Fee" },
    { group: "Shipping & Tax", title: "Other", item: "" },
    { group: "Other", title: "New Blank Item", item: "" },
];

type InvoiceItemSelectProps = {
    value?: InvoiceItemForm,
    size?: "sm",
    warehouseId?: string,
    groupFilter?: string,
    onChange?: (val: InvoiceItemForm | undefined) => void,
    disabledCallback?: (item: InvoiceItemForm) => boolean,
    className?: string
}

const InvoiceItemSelect = ({ warehouseId, groupFilter, disabledCallback, className, size, value, onChange }: InvoiceItemSelectProps) => {
    const { t } = useTranslation();
    const { data: prepServices, isFetching: prepServiceLoading } = useQuery({
        queryKey: ["prep-services-select"],
        queryFn: () => getPrepServiceList({
            page: 1,
            pageSize: 99
        }),
        select: data => data.items
    });

    const items = useMemo(() => _.chain(staticItems.map(s => {
        const item = {
            groupName: s.group,
            serviceName: t(s.item),
            unitPrice: 0,
            count: 1,
            discount: 0,
            total: 0
        };

        return {
            label: t(s.title),
            value: item,
            disabled: disabledCallback?.(item)
        };
    })).push(...(prepServices ?? []).filter(p => p.warehouse.warehouseId === warehouseId).map(c => {
        const item = {
            groupName: t("Prep Services"),
            serviceName: c.description || c.name,
            unitPrice: c.price ?? c.priceRanges?.find(pr => pr.from <= 1 && pr.to >= 1)?.value ?? 0,
            count: 1,
            discount: 0,
            total: 0
        };
        return {
            label: t(c.description || c.name),
            value: item,
            disabled: disabledCallback?.(item)
        };
    })).filter(i => !groupFilter || i.value.groupName === groupFilter).groupBy(i => i.value.groupName).map((options, label) => ({
        label,
        options
    })).value(), [prepServices, t, warehouseId, disabledCallback, groupFilter]);

    return <SelectInput 
        className={className}
        busy={prepServiceLoading}
        showClear
        value={value}
        onChange={onChange} 
        options={items} 
        size={size} />
}

export default InvoiceItemSelect;
import ClipboardCopy from "Components/Common/ClipboardCopy";
import { AddressContract } from "api/types/contracts/common";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type AddressDisplayProps = {
    warehouseName?: string,
    address: AddressContract,
    suitNumber?: string,
    inboundCode?: string,
    className?: string,
    copyButtonClassName?: string,
    inline?: boolean,
    shipmentCode?: string
}

const AddressDisplay = ({ warehouseName, address, inboundCode, suitNumber, inline, shipmentCode, className, copyButtonClassName }: AddressDisplayProps) => {
    const { t } = useTranslation();

    return <div className={classNames("d-flex", {
        "flex-column": !inline,
        "flex-row": inline
    }, className)}>
        {address.name && <div>
            {address.name} <ClipboardCopy className={copyButtonClassName} text={address.name} />
        </div>}
        {suitNumber ? <div>
            {`${address.address1} #${suitNumber}`} <ClipboardCopy className={copyButtonClassName} text={`${address.address1} #${suitNumber}`} />
        </div> : <div>
            {address.address1} <ClipboardCopy className={copyButtonClassName} text={address.address1} />
        </div>}
        {address.address2 && <div>
            {address.address2} <ClipboardCopy className={copyButtonClassName} text={address.address2} />
        </div>}
        {shipmentCode && <div>
            {t("Shipment ID")} {`# ${shipmentCode}`} <ClipboardCopy className={copyButtonClassName} text={`# ${shipmentCode}`} />
        </div>}
        {inboundCode && <div>
            {`# ${inboundCode}`}<ClipboardCopy className={copyButtonClassName} text={`# ${inboundCode}`} />
        </div>}
        <div>
            {address.city && <>{address.city}<ClipboardCopy className={copyButtonClassName} text={address.city} />&nbsp;</>}
            {address.state && <>{address.state}<ClipboardCopy className={copyButtonClassName} text={address.state} />&nbsp;</>}
            {address.zipCode && <>{address.zipCode}<ClipboardCopy className={copyButtonClassName} text={address.zipCode} />&nbsp;</>}
        </div>
        {address.country && <div>
            {address.country.name} <ClipboardCopy className={copyButtonClassName} text={address.country.name} />
        </div>}
        {address.phone && <div>
            {address.phone} <ClipboardCopy className={copyButtonClassName} text={address.phone} />
        </div>}
    </div>
};

export default AddressDisplay;
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./slices";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import _ from 'lodash';

import 'react-toastify/dist/ReactToastify.css';

import "./helpers/validation/overlapCheck";

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            refetchOnWindowFocus: false,
            gcTime: 1 * 60 * 1000,
            staleTime: 1 * 60 * 1000,
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <StrictMode>
        <Provider store={store}>
            <ToastContainer />
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </Provider>
        <SpeedInsights />
        <Analytics />
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Badge, Button, Card, CardBody, CardHeader, CardTitle, Modal, ModalBody, UncontrolledTooltip } from "reactstrap"
import { FbaShipmentBoxContract, FbaShipmentBoxItemContract } from "api/types/contracts/shipping"
import Dimensions from "Components/Displays/UnitDisplay/Dimensions"
import Weight from "Components/Displays/UnitDisplay/Weight"
import { convertWeight } from "helpers/unitConversions"
import Currency from "Components/Displays/UnitDisplay/Currency"
import type { BoxContract } from "api/types/contracts/inventory"
import { useCommon } from "Components/Hooks/CommonHooks"
import type { UnitOfCurrency } from "api/types/contracts/common"
import { postGenerateBinLabel, postGenerateBoxLabel } from "api/printing"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { multiDownload, withCdn } from "helpers/urlHelper"
import { locationOf } from "helpers/locationOf"
import ModalCloseButton from "Components/Common/ModalCloseButton"
import BusyOverlay from "Components/Common/BusyOverlay"
import { postPutawayBin } from "api/inventory"
import { useToggle } from "usehooks-ts"
import LocationInput from "Components/Form/LocationInput"
import type { BinContract } from "api/types/contracts/bin"
import type { BinLocationContract } from "api/types/contracts/locations"

const PackageItemColors = ["#3577f1", "#f06548", "#6559cc", "#f7b84b", "#02a8b5", "#405189", "#f672a7", "#299cdb", "#f1963b", "#0ab39c"];

type ShipmentPackageViewProps = {
    packedBoxes: BoxContract[] | FbaShipmentBoxContract[],
    colorCodes: Record<string, string>,
    displayCurrency: UnitOfCurrency,
    warehouseId: string
}

type ItemAvatarProps = {
    color: string | undefined,
    size: number,
    ratio?: number
};

const ItemAvatar = (props: ItemAvatarProps) => <span className="d-inline-block rounded rounded-circle" style={{
    width: `${props.size}rem`,
    backgroundColor: `${props.color ?? "var(--body-color)"}`,
    aspectRatio: props.ratio ?? 1
}}></span>;

const ShipmentPackageView = ({ packedBoxes, colorCodes, displayCurrency, warehouseId }: ShipmentPackageViewProps) => {
    const { t } = useTranslation();

    return <>
        <div className="d-grid gap-3" style={{
            gridTemplateColumns: "repeat(auto-fill, minmax(21rem, 1fr)"
        }}>
            {packedBoxes.map((box, i) => <ShipmentPackageViewItem 
                box={box} 
                colorCodes={colorCodes} 
                warehouseId={warehouseId}
                displayCurrency={displayCurrency} 
                packageNumber={i + 1} 
                key={i} />)}
        </div>
        <div className="fs-5">
            {t("Total {{count}} package and {{weight}} {{unit}}", { 
                weight: _.sum(packedBoxes.map(p => p.weight)).toFixed(2), 
                count: packedBoxes.length, 
                unit: packedBoxes[0] ? packedBoxes[0].weightUnit : "lbs"
            })}
        </div>
    </>;
}

type ShipmentPackageViewItemProps = {
    box: BoxContract | FbaShipmentBoxContract,
    colorCodes: Record<string, string>,
    displayCurrency: UnitOfCurrency,
    packageNumber: number,
    warehouseId: string
}

const ShipmentPackageViewItem = ({ box, colorCodes, displayCurrency, packageNumber, warehouseId }: ShipmentPackageViewItemProps) => {
    const { t } = useTranslation();
    const { calculateExchange } = useCommon();    
    const [selectedLocation, setSelectedLocation] = useState<BinContract | BinLocationContract>();
    const [changeLocationModal, toggleChangeLocationModal] = useToggle();

    const contents = "contents" in box ? box.contents.map<FbaShipmentBoxItemContract>(c => ({
        productId: c.product.productId,
        count: c.sellableQuantity + c.unusableQuantity,
        asin: c.product.asin ?? "???",
        price: calculateExchange(c.product.buyPrice, c.product.options.unitOfCurrency, displayCurrency)
    })) : box.products;

    const changeLocationMutation = useMutation({
        mutationFn: postPutawayBin,
        onSuccess: () => {
            toggleChangeLocationModal();
        }
    });

    const printBoxLabelMutation = useMutation({
        mutationFn: postGenerateBoxLabel,
        onSuccess: result => {
            if (result.printQueued) {
                toast.success(t("Label sent to printer"));
            }
            else {
                multiDownload([withCdn(result.filePath)]);
            }
        }
    });

    return <>
        <Card className="border">
            <CardHeader className="">
                <div className="d-flex justify-content-between">
                    <CardTitle tag="h6" className="fw-bold mb-0">{t("Package #{{number}}", { number: packageNumber })}</CardTitle>
                    <div className="fw-semibold text-muted">
                        <span id={`package-${packageNumber}`}>
                            <Weight value={box.desi} unit="lbs" /> / <Weight value={convertWeight(box.desi ?? 0, "lbs", "kg")} unit="kg" />
                        </span>
                        <UncontrolledTooltip target={`package-${packageNumber}`}>
                            {t("Desi")}
                        </UncontrolledTooltip>
                    </div>
                </div>
            </CardHeader>
            <CardBody className="">
                <div className="d-grid gap-2" style={{
                    gridTemplateColumns: "repeat(auto-fill, minmax(0.8rem, 1fr))"
                }}>
                    {contents.map((item, j) => {
                        return <React.Fragment key={j}>
                            {Array(item.count).fill(0).map((_, k) => <ItemAvatar color={colorCodes[item.productId]} size={0.75} key={k} />)}
                        </React.Fragment>;
                    })}
                </div>
            </CardBody>
            <CardBody className="border-top py-3">
                <div className="hstack gap-3">
                    <div className="">
                        <Dimensions 
                            value={box} 
                            unit={box.lengthUnit}
                            showAlternative />
                    </div>
                    <div className="">
                        <Weight 
                            value={box.weight} 
                            unit={box.weightUnit}
                            showAlternative />
                    </div>
                    <div className="vstack text-end">
                        <div>
                            <span className="text-muted">{t("Buy Price")}: </span>
                            <Currency
                                value={_.sumBy(contents, p => (p.price ?? 0) * p.count)} 
                                currency={"usd"} />
                        </div>
                        <div>
                            <span className="text-muted">{t("Ordered Qty")}: </span>
                            <span>{_.sumBy(contents, p => p.count)}</span>
                        </div>
                    </div>
                </div>
            </CardBody>
            <CardBody className="border-top py-3">
                <div className="hstack gap-3 align-items-start">
                    <div>
                        {contents.map((product, j) => <div className="hstack gap-1" key={j}>
                            <ItemAvatar color={colorCodes[product.productId]} size={0.75} />
                            <span>{product.asin} x {product.count}</span>
                        </div>)}
                    </div>
                    <div className="vstack text-end">
                        {"boxId" in box && <>
                            <div className="mb-2">
                                <span>{box.boxNumber}</span>
                                <BusyOverlay busy={printBoxLabelMutation.isPending} 
                                    backgroundColor="body-secondary"
                                    spinnerColor="tenant-primary"
                                    size="sm" 
                                    inline 
                                    opaque>
                                        <Button color="ghost-info" size="sm" className="btn-icon ms-1" onClick={() => printBoxLabelMutation.mutate({
                                            boxId: box.boxId
                                        })}>
                                            <i className="ri-printer-fill"></i>
                                        </Button>
                                </BusyOverlay>
                            </div>
                            <div className="text-muted">{t("Box Location")}</div>
                            <div>
                                <span>{box.location ? locationOf(box) : "-"}</span>
                                <Button color="ghost-info" size="sm" className="btn-icon ms-1" onClick={toggleChangeLocationModal}>
                                    <i className="ri-map-pin-line"></i>
                                </Button>
                            </div>
                        </>}
                    </div>
                </div>
            </CardBody>
        </Card>
        <Modal backdrop="static" isOpen={changeLocationModal} toggle={toggleChangeLocationModal} unmountOnClose>
            <ModalCloseButton onClick={toggleChangeLocationModal} />
            <ModalBody>
                <h5 className="mb-1">
                    <span>{t("Change Location")}</span>
                    <Badge color="fnsku" pill className="ms-2 bg-info-subtle text-info fw-normal">{box.boxNumber}</Badge>
                </h5>
                <p className="small text-muted">{t("paragraphs:ChangeLocationModalDescription")}</p>
                <div className="fs-14">
                    <LocationInput warehouseId={warehouseId} onSelect={setSelectedLocation} />
                </div>
                <div className="hstack justify-content-start align-items-center mt-3">
                    <div className="me-auto">
                        {"location" in box && box.location && <>
                            <span className="text-muted">{t("Current Location")}: </span>
                            <span>{locationOf(box)}</span>
                        </>}
                    </div>
                    <BusyOverlay busy={changeLocationMutation.isPending} size="sm" inline>
                        <Button color="info" 
                            disabled={!selectedLocation}
                            onClick={() => changeLocationMutation.mutate({
                                binOrBoxLabel: box.boxNumber,
                                warehouseId: warehouseId,
                                binLocationTargetId: "binLocationId" in selectedLocation! ? selectedLocation.binLocationId : undefined,
                                binTargetId: "binId" in selectedLocation! ? selectedLocation.binId : undefined
                            })} 
                            className="btn-label">
                                <i className="ri-checkbox-circle-fill label-icon"></i>
                                {t("Assign Location")}
                        </Button>
                    </BusyOverlay>
                </div>
            </ModalBody>
        </Modal>
    </>;
}

export { ItemAvatar, PackageItemColors };
export default ShipmentPackageView;
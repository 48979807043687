import BusyOverlay from "Components/Common/BusyOverlay";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import RequiredLabel from "Components/Common/RequiredLabel";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import ProductPickList from "Components/EntitySelects/ProductPickList";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import Checkbox from "Components/Form/Checkbox";
import NumberInput from "Components/Form/NumberInput";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { ProductContract } from "api/types/contracts/products";
import { FbaShipmentCostContract, FbaShipmentCostTableItemContract, ShipmentChargesContract, type FbaShipmentBoxContract, type FbaShipmentContract, type ShipmentTargetContract } from "api/types/contracts/shipping";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Link, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, InputGroup, InputGroupText, Label, Modal, ModalBody, Row, TabContent, TabPane, Table } from "reactstrap";
import * as Yup from "yup";
import ShipmentPackageView, { ItemAvatar, PackageItemColors } from "../../../Components/Shared/ShipmentPackageView";
import CarrierSelectTable from "../../../Components/Shared/Shipment/CarrierSelectTable";
import { packProducts } from "helpers/optimalBox";
import { DangerAlert, WarningAlert } from "Components/Alerts";
import Currency from "Components/Displays/UnitDisplay/Currency";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import PrepServiceSelect from "Components/EntitySelects/PrepServiceSelect";
import { CreateFbaShipmentCommand, FbaShipmentFormItem } from "api/types/commands";
import { WarehouseContract } from "api/types/contracts/warehouses";
import { prepServiceCostTable } from "helpers/prepServiceCostTable";
import ShipmentCostBreakdownView from "Components/Shared/ShipmentCostBreakdownView";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import Weight from "Components/Displays/UnitDisplay/Weight";
import { CarrierAccountServiceContract, PackageInfoContract, ShipmentRateContract } from "api/types/contracts/carrier";
import ShipmentTargetSelect from "Components/EntitySelects/ShipmentTargetSelect";
import { convertDimensions, convertLength, convertWeight } from "helpers/unitConversions";
import TruncatableText from "Components/Common/TruncatableText";
import { inputKeyEventHandler } from "helpers/componentHelpers";
import WeightInput from "Components/Form/UnitInputs/WeightInput";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPrepServiceList } from "api/prepService";
import { postFbaShipment } from "api/fbaShipping";
import { toast } from "react-toastify";
import { postQuickAddProduct } from "api/product";
import StepInput from "Components/Form/StepInput";
import LinedHeader from "Components/Common/LinedHeader";
import { useCommon } from "Components/Hooks/CommonHooks";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { useToggle } from "usehooks-ts";
import AddressDisplay from "Components/Displays/AddressDisplay";

type FormType = Omit<CreateFbaShipmentCommand, "estimatedBoxes">;

const defaultSplitProduct: FbaShipmentFormItem["splitProduct"] = {
    productId: undefined,
    asin: "",
    name: "",
    count: 1,
    totalItemCount: 1,
    splittedItemCount: 1
};

const defaultItem: FbaShipmentFormItem & {
    editable?: boolean
} = {
    productId: "",
    asin: "",
    name: "",
    count: 1,
    buyPrice: 0,
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    prepServices: [],
    isBundle: false,
    isSplit: false,
    bundleProducts: [],
    splitProduct: undefined,
    editable: true
}

const defaultForm = {
    saveAsDraft: false,
    warehouseId: "",
    shipmentTargetId: "",
    carrierAccountServiceId: "",
    notes: "",
    estimatedBoxes: Array<FbaShipmentBoxContract>(),
    estimatedCost: {
        shipping: 0,
        import: 0,
        prep: 0,
        insurance: 0,
        oversize: 0,
        overweight: 0,
        total: 0,
        items: []
    },
    shipmentCharges: {
        totalCost: 0,
        importTaxCost: 0,
        insuranceCost: 0,
        oversizeCost: 0,
        overweightCost: 0,
        shippingCost: 0,
        details: {}
    },
    prepServices: [],
    items: [defaultItem]

} satisfies CreateFbaShipmentCommand;

const WizardForm = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);
    const { calculateExchange } = useCommon();
    const { userProfile, customer } = useProfile();
    const [productSelectModal, toggleProductSelectModal] = useToggle();
    const [completedModal, toggleCompletedModal] = useToggle();
    const [createdShipment, setCreatedShipment] = useState<FbaShipmentContract>();
    const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseContract>();
    const [selectedShipmentTarget, setSelectedShipmentTarget] = useState<ShipmentTargetContract>();
    const [foundProducts, setFoundProducts] = useState<ProductContract[]>([]);
    const [carrierCostInfo, setCarrierCostInfo] = useState<{
        carrierAccountService: CarrierAccountServiceContract | undefined,
        carrierAccountServiceId: string,
        rates: ShipmentRateContract | undefined
    }>();

    const { data: prepServicesList } = useQuery({
        queryKey: ["prep-services-select"],
        queryFn: () => getPrepServiceList({
            page: 1,
            pageSize: 99
        }),
        select: data => data?.items
    });

    useEffect(() => {
        if (userProfile?.user.warehouse?.warehouseId) {
            validation.setFieldValue("warehouseId", userProfile.user.warehouse.warehouseId);
            setSelectedWarehouse(userProfile.user.warehouse);
        }
    }, [userProfile]);

    const retrieveItemMutation = useMutation({
        mutationFn: async ({ i }: {i: number}) => {
            const item = validation.values.items![i];

            if (!item.asin) {
                return;
            }

            return await postQuickAddProduct({ asinList: [item.asin] });
        },
        onSuccess: (products, { i }) => {
            const item = validation.values.items![i];

            if (products) {
                const [found] = products;
                setFoundProducts(_.uniqBy([...foundProducts, found], "productId"));
    
                item.productId = found.productId;
                item.asin = found.asin || "";
                item.name = found.name;
                item.fnsku = found.fnsku;
                item.buyPrice = calculateExchange(found.buyPrice, found.options.unitOfCurrency, unitOfCurrency);
                item.weight = convertWeight(found.weight, found.options.unitOfWeight, unitOfWeight);
                item.length = convertLength(found.length, found.options.unitOfLength, unitOfLength);
                item.width = convertLength(found.width, found.options.unitOfLength, unitOfLength);
                item.height = convertLength(found.height, found.options.unitOfLength, unitOfLength);
                item.prepServices = defaultCheckedPrepServices;
                item.editable = false;

                validation.setFieldValue(`items.${i}`, item);
            }
        }
    });

    const retrieveSplitProductMutation = useMutation({
        mutationFn: async ({ i }: {i: number}) => {
            const item = validation.values.items![i];

            if (!item.splitProduct?.asin) {
                return;
            }

            return await postQuickAddProduct({ asinList: [item.splitProduct.asin] });
        },
        onSuccess: (products, { i }) => {
            const item = validation.values.items![i];

            if (products && item.splitProduct) {
                const [found] = products;
                setFoundProducts(_.uniqBy([...foundProducts, found], "productId"));
    
                item.splitProduct.productId = found.productId;
                item.splitProduct.asin = found.asin || "";
                item.splitProduct.name = found.name;
                
                validation.setFieldValue(`items.${i}`, item);
            }
        }
    });

    const retrieveBundleProductMutation = useMutation({
        mutationFn: async ({ i, j }: {i: number, j: number}) => {
            const item = validation.values.items![i];
            const bundleProduct = item.bundleProducts[j];

            if (!bundleProduct.asin) {
                return;
            }

            return await postQuickAddProduct({ asinList: [bundleProduct.asin] });
        },
        onSuccess: (products, { i, j }) => {
            const item = validation.values.items![i];
            const bundleProduct = item.bundleProducts[j];

            if (products) {
                const [found] = products;
                setFoundProducts(_.uniqBy([...foundProducts, found], "productId"));
    
                bundleProduct.productId = found.productId;
                bundleProduct.asin = found.asin || "";
                bundleProduct.name = found.name;

                validation.setFieldValue(`items.${i}.bundleProducts.${j}`,bundleProduct);
            }
        }
    });

    const createFbaShipmentMutation = useMutation({
        mutationFn: postFbaShipment,
        onSuccess: fbaShipment => {
            setCreatedShipment(fbaShipment);
            toggleCompletedModal();
        }
    })

    const warehousePrepServices = useMemo(() => selectedWarehouse && prepServicesList ? prepServicesList.filter(p => p.warehouse.warehouseId === selectedWarehouse.warehouseId) : [], 
        [selectedWarehouse, prepServicesList]);
    const defaultCheckedPrepServices = useMemo(() => warehousePrepServices.filter(p => p.showInProductOptions && p.checkedByDefault).map(p => p.prepServiceId), [warehousePrepServices]);
    const canUseCustomShipmentLabel = selectedWarehouse && selectedWarehouse.settings.allowCustomShippingLabels 
        && (!selectedWarehouse.settings.limitCustomShippingLabelsToDomesticOnly 
            || selectedWarehouse.address.countryCode === selectedShipmentTarget?.countryCode);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: defaultForm,
        validationSchema: Yup.object<CreateFbaShipmentCommand, TypedShape<FormType>>({
            saveAsDraft: Yup.boolean().required(),
            warehouseId: Yup.string().required(t("You need to select a warehouse")),
            shipmentTargetId: Yup.string().required(t("You need to select a destination")),
            carrierAccountServiceId: Yup.string().required(t("You need to select a carrier service")),
            notes: Yup.string().notRequired(),
            estimatedCost: Yup.object<FbaShipmentCostContract, TypedShape<FbaShipmentCostContract>>({
                shipping: Yup.number().required(),
                import: Yup.number().required(),
                prep: Yup.number().required(),
                insurance: Yup.number().required(),
                oversize: Yup.number().required(),
                overweight: Yup.number().required(),
                total: Yup.number().required(),
                items: Yup.array<FbaShipmentCostTableItemContract>(Yup.object<FbaShipmentCostTableItemContract, TypedShape<FbaShipmentCostTableItemContract>>({
                    prepServiceId: Yup.string().required(),
                    serviceName: Yup.string().required(),
                    discount: Yup.number().notRequired(),
                    total: Yup.number().required(),
                    count: Yup.number().required(),
                    unitPrice: Yup.number().required(),
                    deletable: Yup.boolean().notRequired()
                }).required()).required()
            }).required(),
            shipmentCharges: Yup.object({
                totalCost: Yup.number().required(),
                importTaxCost: Yup.number().required(),
                insuranceCost: Yup.number().required(),
                oversizeCost: Yup.number().required(),
                overweightCost: Yup.number().required(),
                shippingCost: Yup.number().required(),
                details: Yup.object().required()
            }).required(),
            prepServices: Yup.array().of(Yup.string().required()).required(),
            items: Yup.array().of(Yup.object({
                productId: Yup.string().notRequired(),
                asin: Yup.string().required(t("You need to enter an ASIN")),
                name: Yup.string().required(t("You need to enter a name")),
                count: Yup.number().required(t("You need to enter a count")),
                buyPrice: Yup.number().required(t("You need to enter a buy price")),
                fnsku: Yup.string().notRequired(),
                weight: Yup.number().required(t("You need to enter a weight")),
                length: Yup.number().required(t("You need to enter a length")),
                width: Yup.number().required(t("You need to enter a width")),
                height: Yup.number().required(t("You need to enter a height")),
                prepServices: Yup.array().of(Yup.string().required()).required(),
                isBundle: Yup.boolean().required(),
                isSplit: Yup.boolean().required(),
                bundleProducts: Yup.array().of(Yup.object({
                    productId: Yup.string().notRequired(),
                    asin: Yup.string().required(t("You need to enter an ASIN")),
                    name: Yup.string().required(t("You need to enter a name")),
                    count: Yup.number().required(t("You need to enter a count"))
                })).when("isBundle", ([isBundle], schema) => isBundle ? schema.min(1, t("You need to add at least one bundle item")) : schema).required(),
                splitProduct: Yup.object({
                    productId: Yup.string().notRequired(),
                    asin: Yup.string().required(t("You need to enter an ASIN")),
                    name: Yup.string().required(t("You need to enter a name")),
                    count: Yup.number().required(t("You need to enter a count")),
                    totalItemCount: Yup.number().required(t("You need to enter a total item count")),
                    splittedItemCount: Yup.number().required(t("You need to enter a splitted item count"))
                }).default(undefined).when("isSplit", ([isSplit], schema) => isSplit ? schema.required() : schema.notRequired())
            })).required().min(1, t("You need to add at least one product"))
        }),
        onSubmit: values => {
            values.estimatedBoxes = packedBoxes;

            createFbaShipmentMutation.mutate(values as CreateFbaShipmentCommand);
        }
    });

    const productColorCodes = useMemo(() => _
        .chain(validation.values.items)
        .map((item, i) => [item.productId, PackageItemColors[i % PackageItemColors.length]])
        .uniqWith(_.isEqual).fromPairs().value(), 
    [validation.values.items.map(i => i.productId).join(",")]);

    const selectedProducts = useMemo(() => validation.values.items.map((item, i) => {
        const found = foundProducts.find(p => p.productId === item.productId);

        return {
            item,
            product: found
        };
    }), [foundProducts, validation.values.items]);

    const unitOfLength = useMemo(() => selectedWarehouse?.settings.unitOfLength ?? "inch", [selectedWarehouse]);
    const unitOfWeight = useMemo(() => selectedWarehouse?.settings.unitOfWeight ?? "lbs", [selectedWarehouse]);
    const unitOfCurrency = useMemo(() => selectedWarehouse?.settings.unitOfCurrency ?? "usd", [selectedWarehouse]);
    const totalWeight = useMemo(() => _.sumBy(selectedProducts, p => p.item.count * convertWeight(p.item.weight, p.product?.options.unitOfWeight ?? "lbs", unitOfWeight)), [selectedProducts, unitOfWeight]);
    const totalCount = useMemo(() => _.sumBy(selectedProducts, p => p.item.count), [selectedProducts]);
    const totalCostOfGoods = useMemo(() => _.sumBy(selectedProducts, ({ item, product }) => calculateExchange(item.buyPrice * item.count, product?.options.unitOfCurrency ?? "usd", selectedWarehouse?.settings.unitOfCurrency ?? "usd")), [selectedProducts]);

    const removeItem = (i: number) => {
        validation.setFieldValue("items", validation.values.items.filter((_, j) => j !== i));
    }

    const addItem = () => {
        const newItem = _.cloneDeep(defaultItem);
        const newItems = [...validation.values.items];

        newItem.prepServices = defaultCheckedPrepServices;

        newItems.push(newItem);
        validation.setFieldValue("items", newItems);
    }

    const addBundleProduct = (i: number) => {
        const newBundleProducts = [...validation.values.items[i].bundleProducts];
        newBundleProducts.push({
            asin: "",
            name: "",
            count: 1
        });
        
        validation.setFieldValue(`items.${i}.bundleProducts`, newBundleProducts);
    }

    const removeBundleProduct = (i: number, j: number) => {
        const newBundleProducts = [...validation.values.items[i].bundleProducts];
        newBundleProducts.splice(j, 1);
        validation.setFieldValue(`items.${i}.bundleProducts`, newBundleProducts);
    }

    const boxProducts = useMemo(() => selectedProducts.map(p => {
        if (p.item.isSplit && p.item.splitProduct) {
            return {
                productId: p.item.splitProduct.productId || "",
                asin: p.item.splitProduct.asin,
                length: p.item.splitProduct.splittedItemCount,
                width: p.item.splitProduct.splittedItemCount,
                height: p.item.splitProduct.splittedItemCount,
                weight: p.item.splitProduct.splittedItemCount,
                lengthUnit: p.product?.options.unitOfLength ?? unitOfLength,
                weightUnit: p.product?.options.unitOfWeight ?? unitOfWeight,
                count: p.item.splitProduct.count
            };
        }
        else {
            return {
                productId: p.item.productId || "",
                asin: p.item.asin,
                length: p.item.length,
                width: p.item.width,
                height: p.item.height,
                weight: p.item.weight,
                lengthUnit: p.product?.options.unitOfLength ?? unitOfLength,
                weightUnit: p.product?.options.unitOfWeight ?? unitOfWeight,
                count: p.item.count
            };
        }
    }), [selectedProducts, unitOfLength, unitOfWeight]);

    const recalculateSplitItemCount = (i: number, count: number | undefined, totalItemCount: number | undefined, splittedItemCount: number | undefined) => {
        const item = validation.values.items[i];

        if (!item.splitProduct) {
            return;
        }

        item.count = Math.ceil((count ?? 1) * (totalItemCount ?? 1) / (splittedItemCount ?? 1));

        validation.setFieldValue(`items.${i}`, item);
    }

    const packedBoxes = useMemo(() => packProducts(boxProducts, unitOfLength, unitOfWeight), [boxProducts, unitOfLength, unitOfWeight]);
    
    const allPrepServices = useMemo(() => prepServiceCostTable(prepServicesList ?? [], selectedProducts.map(p => ({ count: p.item.count, prepServices: p.item.prepServices })), validation.values.prepServices!, packedBoxes.length), 
        [selectedProducts, validation.values.prepServices, prepServicesList, packedBoxes.length]);
    const totalPrepServicesCost = useMemo(() => _.sumBy(allPrepServices, "total"), [allPrepServices]);

    useEffect(() => {
        if (warehousePrepServices.length > 0) {
            const defaultPrepServiecs = warehousePrepServices.filter(p => p.includeAsDefault);

            validation.setFieldValue("prepServices", defaultPrepServiecs.map(p => p.prepServiceId));
        }
    }, [warehousePrepServices]);

    useEffect(() => {
        (async () => {
            if (carrierCostInfo) {
                await validation.setFieldValue("carrierAccountServiceId", carrierCostInfo.carrierAccountServiceId);
            }

            if (carrierCostInfo?.rates?.success) {
                await validation.setFieldValue("estimatedCost.import", carrierCostInfo.rates.importTaxCost);
                await validation.setFieldValue("estimatedCost.shipping", carrierCostInfo.rates.shippingCost);
                await validation.setFieldValue("estimatedCost.insurance", carrierCostInfo.rates.insuranceCost);
                await validation.setFieldValue("estimatedCost.oversize", carrierCostInfo.rates.oversizeCost);
                await validation.setFieldValue("estimatedCost.overweight", carrierCostInfo.rates.overweightCost);
                await validation.setFieldValue("estimatedCost.total", carrierCostInfo.rates.totalCost + totalPrepServicesCost);

                await validation.setFieldValue("shipmentCharges", {
                    ...carrierCostInfo.rates,
                    details: carrierCostInfo.rates.details ?? {}
                } as ShipmentChargesContract);
            }

            await validation.setFieldValue("estimatedCost.prep", totalPrepServicesCost);
            await validation.setFieldValue("estimatedCost.items", allPrepServices);

            await validation.validateForm();
        })();
    }, [allPrepServices, carrierCostInfo, totalPrepServicesCost]);

    const allowAsinDataEditing = customer?.properties.allowAsinDataEditing;

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    <div className="hstack mb-3">
                        <div className="avatar-sm me-3">
                            <div className="avatar-title bg-warning border-1 border-dark fs-5 rounded-circle text-dark">1</div>
                        </div>
                        <h6 className="mb-0">{t("Select Products & Destination")}</h6>
                    </div>
                    <Card>
                        <CardHeader className="border-0 pb-0">
                            <Row className="align-items-center">
                                <div className="col-sm">
                                    <h5 className="card-title mb-0">{t("Destination Amazon Warehouse")}</h5>
                                </div>
                                <div className="col-sm-auto">
                                    <Button type="button" color="primary"onClick={toggleProductSelectModal}>{t("Select From Inventory")}</Button>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-center">
                                <Col lg={2} md={2} className="">{t("Warehouse")}</Col>
                                <Col lg={5} md={4} className="">
                                    <ValidationWrapper validation={validation} field="warehouseId">
                                        <WarehouseSelect fullName onSelect={setSelectedWarehouse} />
                                    </ValidationWrapper>
                                </Col>
                                <Col lg={1} md={2} className="">{t("Send To")}</Col>
                                <Col lg={4} md={4} className="">
                                    <ValidationWrapper validation={validation} field="shipmentTargetId">
                                        <ShipmentTargetSelect defaults onSelect={setSelectedShipmentTarget} />
                                    </ValidationWrapper>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {validation.values.items.map((item, i) => <div key={i}>
                        <Card body>
                            <div className="position-relative">
                                {validation.values.items.length > 1 && <div className="position-absolute top-0 start-100 translate-middle">
                                    <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => removeItem(i)}>
                                        <i className="ri-close-fill fs-2" />
                                    </Button>
                                </div>}
                                <div className="pe-3">
                                    <Row className="mb-3 g-2">
                                        <Col lg={2} md={4}>
                                            <RequiredLabel for={`items.${i}.asin`}>{t("ASIN")}</RequiredLabel>
                                            <BusyOverlay busy={retrieveItemMutation.isPending && retrieveItemMutation.variables.i === i} size="sm">
                                                <ValidationWrapper validation={validation} field={`items.${i}.asin`}>
                                                    <TextInput placeholder={t("Enter ASIN")} 
                                                        onBlur={() => retrieveItemMutation.mutate({ i })} 
                                                        onKeyDown={inputKeyEventHandler("Enter", e => retrieveItemMutation.mutate({ i }))} />
                                                </ValidationWrapper>
                                            </BusyOverlay>
                                        </Col>
                                        <Col lg={4} md={4}>
                                            <RequiredLabel for={`items.${i}.name`}>{t("Product Name")}</RequiredLabel>
                                            <ValidationWrapper validation={validation} field={`items.${i}.name`}>
                                                <TextInput disabled={!validation.values.items[i].editable && !allowAsinDataEditing} />
                                            </ValidationWrapper>
                                        </Col>
                                        <Col lg={1} md={2} xs={4}>
                                            <RequiredLabel for={`items.${i}.count`}>{t("Count")}</RequiredLabel>
                                            <ValidationWrapper validation={validation} field={`items.${i}.count`}>
                                                <StepInput readOnly={item.isSplit} min={1} />
                                            </ValidationWrapper>
                                        </Col>
                                        <Col lg={1} md={2} xs={4}>
                                            <RequiredLabel for={`items.${i}.buyPrice`}>{t("Buy Price")}</RequiredLabel>
                                            <ValidationWrapper validation={validation} field={`items.${i}.buyPrice`}>
                                                <CurrencyInput disabled={!validation.values.items[i].editable && !allowAsinDataEditing} />
                                            </ValidationWrapper>
                                        </Col>
                                        <Col lg={3} md={6} xs={6}>
                                            <RequiredLabel>{t("Dimensions")}</RequiredLabel>
                                            <div className="hstack gap-2">
                                                <InputGroup>
                                                    <ValidationWrapper validation={validation} field={`items.${i}.width`}>
                                                        <NumberInput disabled={!validation.values.items[i].editable && !allowAsinDataEditing} placeholder="Width" />
                                                    </ValidationWrapper>
                                                    <ValidationWrapper validation={validation} field={`items.${i}.height`}>
                                                        <NumberInput disabled={!validation.values.items[i].editable && !allowAsinDataEditing} placeholder="Height" />
                                                    </ValidationWrapper>
                                                    <ValidationWrapper validation={validation} field={`items.${i}.length`}>
                                                        <NumberInput disabled={!validation.values.items[i].editable && !allowAsinDataEditing} placeholder="Length" />
                                                    </ValidationWrapper>
                                                    <InputGroupText>{unitOfLength}</InputGroupText>
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col lg={1} md={2} xs={4}>
                                            <RequiredLabel for={`items.${i}.weight`}>{t("Weight")}</RequiredLabel>
                                            <ValidationWrapper validation={validation} field={`items.${i}.weight`}>
                                                <WeightInput disabled={!validation.values.items[i].editable && !allowAsinDataEditing} unit={unitOfWeight} />
                                            </ValidationWrapper>
                                        </Col>
                                    </Row>
                                    <div className="hstack gap-3 mb-2">
                                        <ValidationWrapper validation={validation} field={`items.${i}.isBundle`}>
                                            <Checkbox label={t("Is Bundle")} disabled={item.isSplit} onClick={() => {
                                                if (item.isBundle) {
                                                    item.bundleProducts = [];
                                                }
                                                else {
                                                    addBundleProduct(i);
                                                }
                                            }} />
                                        </ValidationWrapper>
                                        <ValidationWrapper validation={validation} field={`items.${i}.isSplit`}>
                                            <Checkbox label={t("Is Split")} disabled={item.isBundle} onClick={() => {
                                                if (item.isSplit) {
                                                    item.splitProduct = undefined;
                                                }
                                                else {
                                                    item.splitProduct = defaultSplitProduct;

                                                    if (prepServicesList) {
                                                        
                                                    }
                                                    validation.setFieldValue(`items.${i}.prepServices`, []);
                                                }
                                            }} />
                                        </ValidationWrapper>
                                    </div>
                                    {item.isBundle && <div className="mb-2">
                                        <LinedHeader tag="h6">{t("Bundle Products")}</LinedHeader>
                                        <div className="vstack gap-2">
                                            {item.bundleProducts.map((bundle, j) => <div key={j} className="hstack gap-2 align-content-center">
                                                <RequiredLabel for={`items.${i}.bundleProducts.${j}.asin`} className="flex-shrink-0">{t("ASIN")}</RequiredLabel>
                                                <BusyOverlay busy={retrieveBundleProductMutation.isPending && retrieveBundleProductMutation.variables.i === i && retrieveBundleProductMutation.variables.j === j} size="sm">
                                                    <ValidationWrapper validation={validation} field={`items.${i}.bundleProducts.${j}.asin`}>
                                                        <TextInput size="sm" style={{ flex: "2" }} 
                                                            placeholder={t("Enter ASIN")} 
                                                            onBlur={() => retrieveBundleProductMutation.mutate({ i, j })} 
                                                        />
                                                    </ValidationWrapper>
                                                </BusyOverlay>
                                                <RequiredLabel for={`items.${i}.bundleProducts.${j}.name`} className="flex-shrink-0">{t("Name")}</RequiredLabel>
                                                <ValidationWrapper validation={validation} field={`items.${i}.bundleProducts.${j}.name`}>
                                                    <TextInput size="sm" style={{ flex: "4" }} />
                                                </ValidationWrapper>
                                                <RequiredLabel for={`items.${i}.bundleProducts.${j}.count`} className="flex-shrink-0">{t("Count")}</RequiredLabel>
                                                <ValidationWrapper validation={validation} field={`items.${i}.bundleProducts.${j}.count`}>
                                                    <StepInput size="sm" style={{ flex: "1" }} min={1}  />
                                                </ValidationWrapper>
                                                <Label className="flex-shrink-0 mb-0">{t("Total Needed")}</Label>
                                                <NumberInput size="sm" style={{ flex: "1" }} readOnly value={item.count * bundle.count} />
                                                <Button color="ghost-danger" size="sm" className="btn-icon" onClick={() => removeBundleProduct(i, j)}>
                                                    <i className="ri-delete-bin-line fs-16" />
                                                </Button>
                                            </div>)}
                                            <div>
                                                <Button color="ghost-info" size="sm" onClick={() => addBundleProduct(i)}>{t("Add Another")}</Button>
                                            </div>
                                        </div>
                                    </div>}
                                    {item.isSplit && <div className="mb-2">
                                        <LinedHeader tag="h6">{t("Split Product")}</LinedHeader>
                                        <div className="hstack gap-2">
                                            <RequiredLabel for={`items.${i}.splitProduct.asin`} className="flex-shrink-0">{t("ASIN")}</RequiredLabel>
                                            <BusyOverlay busy={retrieveSplitProductMutation.isPending && retrieveSplitProductMutation.variables.i === i} size="sm">
                                                <ValidationWrapper validation={validation} field={`items.${i}.splitProduct.asin`}>
                                                    <TextInput size="sm" style={{ flex: "2" }} 
                                                        placeholder={t("Enter ASIN")} 
                                                        onKeyDown={inputKeyEventHandler("Enter", e => retrieveSplitProductMutation.mutate({ i }))}
                                                        onBlur={() => retrieveSplitProductMutation.mutate({ i })} />
                                                </ValidationWrapper>
                                            </BusyOverlay>
                                            <RequiredLabel for={`items.${i}.splitProduct.name`} className="flex-shrink-0">{t("Name")}</RequiredLabel>
                                            <ValidationWrapper validation={validation} field={`items.${i}.splitProduct.name`}>
                                                <TextInput size="sm" style={{ flex: "3" }} />
                                            </ValidationWrapper>
                                            <RequiredLabel for={`items.${i}.splitProduct.count`} className="flex-shrink-0">{t("Count")}</RequiredLabel>
                                            <ValidationWrapper validation={validation} field={`items.${i}.splitProduct.count`}>
                                                <StepInput size="sm" style={{ flex: "1" }} min={1}  onChange={val => recalculateSplitItemCount(i, val, item.splitProduct?.totalItemCount, item.splitProduct?.splittedItemCount)} />
                                            </ValidationWrapper>
                                            <RequiredLabel for={`items.${i}.splitProduct.totalItemCount`} className="flex-shrink-0">{t("Total Item Count")}</RequiredLabel>
                                            <ValidationWrapper validation={validation} field={`items.${i}.splitProduct.totalItemCount`}>
                                                <StepInput size="sm" style={{ flex: "1" }} min={1}  onChange={val => recalculateSplitItemCount(i, item.splitProduct?.count, val, item.splitProduct?.splittedItemCount)} />
                                            </ValidationWrapper>
                                            <RequiredLabel for={`items.${i}.splitProduct.splittedItemCount`} className="flex-shrink-0">{t("Splitted Item Count")}</RequiredLabel>
                                            <ValidationWrapper validation={validation} field={`items.${i}.splitProduct.splittedItemCount`}>
                                                <StepInput size="sm" min={1} style={{ flex: "1" }} onChange={val => recalculateSplitItemCount(i, item.splitProduct?.count, item.splitProduct?.totalItemCount, val)} />
                                            </ValidationWrapper>
                                        </div>
                                    </div>}
                                    <div>
                                        <LinedHeader tag="h6">{t("Options")}</LinedHeader>
                                        <ValidationWrapper validation={validation} field={`items.${i}.prepServices`}>
                                            <PrepServiceSelect productOptionsOnly channel="fba" checkboxes isMulti warehouseId={selectedWarehouse?.warehouseId} />
                                        </ValidationWrapper>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>)}
                    <div>
                        <Button color="ghost-primary" onClick={addItem}>{t("Add Another")}</Button>
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        <ValidatorButton validation={validation} type="button" 
                            fields={["items", "warehouseId", "shipmentTargetId"]} 
                            onClick={() => setActiveTab(2)}>
                            {t("Confirm")}
                        </ValidatorButton>
                    </div>
                </TabPane>
                <TabPane tabId={2}>
                    <div className="hstack mb-3">
                        <div className="avatar-sm me-3">
                            <div className="avatar-title bg-warning border-1 border-dark fs-5 rounded-circle text-dark">2</div>
                        </div>
                        <h6 className="mb-0">{t("Packing & Shipping Details")}</h6>
                    </div>
                    <Card>
                        <CardHeader className="border-0 pb-0">
                            <div className="hstack">
                                <h5 className="card-title mb-0">{t("Selected Products")}</h5>
                                <Button type="button" size="sm" color="warning" className="ms-auto" onClick={() => setActiveTab(1)}>
                                    {t("Change Selected Products")}
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="table-card table-responsive">
                                <Table className="mb-0 align-middle">
                                    <thead>
                                        <tr>
                                            <th>{t("Image")}</th>
                                            <th>{t("Name")}</th>
                                            <th>{t("ASIN")}</th>
                                            <th>{t("Count")}</th>
                                            <th>{t("Dimensions")}</th>
                                            <th>{t("Weight")}</th>
                                            <th>{t("Buy Price")}</th>
                                            <th>{t("Total Price")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedProducts.map(({ item, product }, i) => <tr key={i}>
                                            <td>{product && <ProductImageDisplay product={product} />}</td>
                                            <td>
                                                <div>
                                                    <TruncatableText maxLines={2}>{item.name}</TruncatableText>
                                                </div>
                                                {item.isBundle && <>
                                                    <div className="text-danger small">{t("Bundling from {{products}}", {
                                                        products: item.bundleProducts.map(p => `${p.count}x${p.asin}`).join(", ")
                                                    })}</div>
                                                </>}
                                                {item.isSplit && <div className="text-danger small">{t("Split from {{asin}}", { asin: item.splitProduct?.asin })}</div>}
                                                <div className="fs-10">
                                                    <strong>{t("Prep Services")}{": "}</strong>
                                                    <ListSummaryDisplay items={item.prepServices.map(p => prepServicesList?.find(ps => ps.prepServiceId === p))} displayCount={4} seperator=", " displayFn={p => p?.name} />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="hstack gap-1 align-items-center">
                                                    <ItemAvatar color={productColorCodes[product?.productId ?? ""]} size={0.5} /> {item.asin}
                                                </div>
                                            </td>
                                            <td>
                                                <ValidationWrapper validation={validation} field={`items.${i}.count`}>
                                                    <StepInput min={1} readOnly={item.isSplit} style={{ width: "6rem"}} />
                                                </ValidationWrapper>
                                            </td>
                                            <td>
                                                {selectedWarehouse && product && <div>
                                                    <Dimensions 
                                                        value={convertDimensions(item, product.options.unitOfLength, selectedWarehouse.settings.unitOfLength)} 
                                                        unit={selectedWarehouse.settings.unitOfLength}
                                                        showAlternative />
                                                </div>}
                                            </td>
                                            <td>
                                                {selectedWarehouse && product && <div>
                                                    <Weight 
                                                        value={convertWeight(item.weight, product.options.unitOfWeight, selectedWarehouse.settings.unitOfWeight)} 
                                                        unit={selectedWarehouse.settings.unitOfWeight}
                                                        showAlternative />
                                                </div>}
                                            </td>
                                            <td>
                                                {selectedWarehouse && product && <div>
                                                    <Currency 
                                                        value={calculateExchange(item.buyPrice, product.options.unitOfCurrency, selectedWarehouse.settings.unitOfCurrency)} 
                                                        currency={selectedWarehouse.settings.unitOfCurrency} />
                                                </div>}
                                                {product?.options.unitOfCurrency !== selectedWarehouse?.settings.unitOfCurrency && <div className="text-muted small">
                                                    <Currency 
                                                        value={item.buyPrice} 
                                                        currency={product?.options.unitOfCurrency} />
                                                </div>}
                                            </td>
                                            <td>
                                            {selectedWarehouse && product && <div>
                                                    <Currency 
                                                        value={calculateExchange(item.buyPrice * item.count, product.options.unitOfCurrency, selectedWarehouse.settings.unitOfCurrency)} 
                                                        currency={selectedWarehouse.settings.unitOfCurrency} />
                                                </div>}
                                                {product?.options.unitOfCurrency !== selectedWarehouse?.settings.unitOfCurrency && <div className="text-muted small">
                                                    <Currency 
                                                        value={item.buyPrice * item.count} 
                                                        currency={product?.options.unitOfCurrency} />
                                                </div>}
                                            </td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={3} className="text-end">{t("Total")}</th>
                                            <th>{totalCount}</th>
                                            <th></th>
                                            <th><Weight value={totalWeight} unit={unitOfWeight} /></th>
                                            <th></th>
                                            <td><Currency value={totalCostOfGoods} currency={selectedWarehouse?.settings.unitOfCurrency} /></td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className="border-0 pb-0">
                            <h5 className="card-title mb-0">{t("Packages")}</h5>
                        </CardHeader>
                        <CardBody>
                            {selectedWarehouse && <ShipmentPackageView 
                                displayCurrency={selectedWarehouse.settings.unitOfCurrency ?? "usd"}
                                colorCodes={productColorCodes} 
                                warehouseId={selectedWarehouse.warehouseId}
                                packedBoxes={packedBoxes} />}
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className="border-0 pb-0">
                            <h5 className="card-title mb-0">{t("Select Carrier")}</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="table-responsive table-card">
                                {validation.values.shipmentTargetId && validation.values.warehouseId && <>
                                    <CarrierSelectTable 
                                        allowEmpty={canUseCustomShipmentLabel}
                                        emptyOption={t("I will provide my own shipping label")}
                                        boxes={packedBoxes.map<PackageInfoContract>((p, i) => ({
                                            height: p.height,
                                            length: p.length,
                                            width: p.width,
                                            weight: p.weight,
                                            desi: p.desi,
                                            value: _.chain(p.products)
                                                .map(p => {
                                                    const item = validation.values.items?.find(fp => fp.productId === p.productId);
                                                    
                                                    return (item?.buyPrice ?? 0) * p.count;
                                                })
                                                .sum()
                                                .value(), 
                                            lengthUnit: unitOfLength,
                                            weightUnit: unitOfWeight,
                                            shipmentTargetId: validation.values.shipmentTargetId,
                                            identifier: `Package #${i + 1}`,
                                            currency: selectedWarehouse?.settings.unitOfCurrency ?? "usd",
                                            items: p.products.map(p => ({
                                                productId: p.productId,
                                                quantity: p.count
                                            }))
                                        }))} 
                                        originCountryCode={selectedWarehouse?.address.countryCode ?? "USA"}
                                        disableAutoRates={activeTab === 1}
                                        shipmentTargetId={validation.values.shipmentTargetId} 
                                        originWarehouseId={validation.values.warehouseId} 
                                        value={validation.values.carrierAccountServiceId}
                                        onSelect={setCarrierCostInfo} />
                                </>}
                            </div>
                        </CardBody>
                    </Card>
                    {validation.values.estimatedCost && <ShipmentCostBreakdownView 
                        value={validation.values.estimatedCost} 
                        currency={selectedWarehouse?.settings.unitOfCurrency ?? "usd"}
                        warehouseId={selectedWarehouse?.warehouseId} 
                        canAdd 
                        onPrepServiceAdded={prepServiceId => {
                            validation.setFieldValue("prepServices", [...validation.values.prepServices, prepServiceId]);
                        }}
                        onPrepServiceRemoved={prepServiceId => {
                            validation.setFieldValue("prepServices", validation.values.prepServices.filter(p => p !== prepServiceId));
                        }}
                        onChange={cost => {
                            validation.setFieldValue("estimatedCost", cost);
                        }} />}
                    <Card>
                        <CardHeader className="border-0 pb-0">
                            <h5 className="card-title mb-0">{t("Notes")}</h5>
                        </CardHeader>
                        <CardBody>
                            <ValidationWrapper validation={validation} field="notes">
                                <TextInput type="textarea" />
                            </ValidationWrapper>
                        </CardBody>
                    </Card>
                    <div className="vstack justify-content-center gap-3 mb-3">
                        <WarningAlert kind="top-border">{t("paragraphs:GetAQuoteWarning")}</WarningAlert>
                        {createFbaShipmentMutation.isError && <DangerAlert>{createFbaShipmentMutation.error.message}</DangerAlert>}
                        <div className="w-25">
                            <BusyOverlay busy={createFbaShipmentMutation.isPending} size="sm">
                                <ValidatorButton validation={validation} type="submit" className="w-100">
                                    {t("Create FBA Shipment")}
                                </ValidatorButton>
                            </BusyOverlay>
                        </div>
                        <div>
                            <BusyOverlay busy={createFbaShipmentMutation.isPending} size="sm">
                                <ValidatorButton validation={validation} type="button" className="w-100" onClick={async () => {
                                    await validation.setFieldValue("saveAsDraft", true);
                                    await validation.submitForm();
                                }}>
                                    {t("Create as Draft")}
                                </ValidatorButton>
                            </BusyOverlay>
                        </div>
                    </div>
                </TabPane>
            </TabContent>
        </Form>
        <Modal backdrop="static" isOpen={productSelectModal} toggle={toggleProductSelectModal} size="xl">
            <ModalBody>
                <ModalCloseButton onClick={toggleProductSelectModal} />
                <ProductPickList onSelectComplete={selected => {
                    toggleProductSelectModal();

                    if (selected.length === 0) {
                        return;
                    }

                    setFoundProducts(_.uniqBy([...foundProducts, ...selected], p => p.productId));

                    const newItems = [...validation.values.items.filter(i => !!i.productId)];
                    selected.forEach(product => {
                        const exists = validation.values.items.find(item => item.productId === product.productId);

                        if (exists) {
                            return;
                        }

                        newItems.push({
                            productId: product.productId,
                            asin: product.asin || "",
                            name: product.name,
                            fnsku: product.fnsku,
                            count: 1,
                            buyPrice: calculateExchange(product.buyPrice, product.options.unitOfCurrency, unitOfCurrency),
                            weight: convertWeight(product.weight, product.options.unitOfWeight, unitOfWeight),
                            length: convertLength(product.length, product.options.unitOfLength, unitOfLength),
                            width: convertLength(product.width, product.options.unitOfLength, unitOfLength),
                            height: convertLength(product.height, product.options.unitOfLength, unitOfLength),
                            prepServices: defaultCheckedPrepServices,
                            isBundle: false,
                            isSplit: false,
                            bundleProducts: [],
                            splitProduct: undefined,
                            editable: false
                        });
                    });

                    validation.setFieldValue("items", newItems);
                }} />
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={completedModal} toggle={toggleCompletedModal} centered unmountOnClose size="lg">
            <ModalBody>
                {createdShipment && <div className="text-center pb-4">
                    <i className="ri-mail-send-line text-success ri-5x"></i>
                    <h4>{t("Shipment Created")}!</h4>
                    <p>{t("Your shipment # {{shipmentCode}} successfully created", { shipmentCode: createdShipment.shipmentCode })}</p>
                    <p className="mb-4">{t("You must send your shipment to address below")}</p>
                    <AddressDisplay 
                        inline
                        className="fs-16 gap-2 flex-wrap justify-content-center"
                        address={createdShipment.warehouse.address} 
                        suitNumber={createdShipment.customer.suitNumber}
                        warehouseName={createdShipment.warehouse.name}
                        shipmentCode={createdShipment.shipmentCode}  />
                </div>}
                <div className="d-flex justify-content-center gap-3">
                    <Link to="/fba-shipments" className="btn btn-success">{t("Shipment List")}</Link>
                    <Link to="/get-a-quote" className="btn btn-primary btn-label">
                        <i className="ri-add-line label-icon"></i>
                        {t("New Shipment")}
                    </Link>
                </div>
            </ModalBody>
        </Modal>
    </>;
}

export default WizardForm;
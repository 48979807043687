import classNames from "classnames";
import { PropsWithChildren, useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";

type TruncatableTextProps = PropsWithChildren & {
    maxLines?: 1 | 2 | 3 | 4 | 5,
    className?: string,
    noUnderline?: boolean
}

const TruncatableText = ({ children, maxLines, className, noUnderline }: TruncatableTextProps) => {
    const lineClass = maxLines && maxLines > 1 ? `text-truncate-${maxLines}` : "";
    const spanRef = useRef<HTMLSpanElement>(null);
    const tooltipNeeded = !!spanRef.current && spanRef.current.scrollHeight > spanRef.current.clientHeight;

    return <>
        <span className={classNames("text-truncate", lineClass, className, {
            "text-decoration-dotted-underline": !noUnderline && tooltipNeeded
        })} ref={spanRef}>
            {children}
        </span>
        {tooltipNeeded && <UncontrolledTooltip target={spanRef}>
            {children}
        </UncontrolledTooltip>}
    </>;
}

export default TruncatableText;
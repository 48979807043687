import BusyOverlay from "Components/Common/BusyOverlay";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Loader from "Components/Common/Loader";
import FbaShipmentItemStatusBadge from "Components/Displays/FbaShipmentItemStatusBadge";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import Currency from "Components/Displays/UnitDisplay/Currency";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import Weight from "Components/Displays/UnitDisplay/Weight";
import PrepServiceSelect from "Components/EntitySelects/PrepServiceSelect";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { ItemAvatar, PackageItemColors } from "Components/Shared/ShipmentPackageView";
import { FbaShipmentContract, FbaShipmentItemContract, FbaShipmentItemStatus, FbaShipmentStatus, FbaShipmentUpdateFormItem } from "api/types/contracts/shipping";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Form, Modal, ModalBody, Table } from "reactstrap";
import { removeFbaShipmentItem, updateFbaShipmentItems } from "slices/shipping/fbaShipping/thunk";
import * as Yup from "yup";
import AddProductForm from "./_AddProductForm";
import { commaSeperated } from "helpers/string";
import TruncatableText from "Components/Common/TruncatableText";
import { useCommon } from "Components/Hooks/CommonHooks";
import { convertDimensions, convertWeight } from "helpers/unitConversions";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import type { ProductContract } from "api/types/contracts/products";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ProductEditForm from "Components/Shared/ProductEditForm";
import StepInput from "Components/Form/StepInput";
import { inputKeyEventHandler } from "helpers/componentHelpers";
import { useMutation } from "@tanstack/react-query";
import FbaShipmentItemReceivingStatusDisplay from "Components/Displays/FbaShipmentItemReceivingStatusDisplay";

const createFbaShipmentItemsForm = (fbaShipment: FbaShipmentContract | undefined): FbaShipmentUpdateFormItem[] => {
    return fbaShipment?.items.map(i => ({
        productId: i.product.productId,
        count: i.count,
        fnsku: i.fnsku,
        expireDate: undefined,
        status: undefined,
        prepServices: i.prepServices.map(p => p.prepService.prepServiceId)
    })) ?? [];
}

type ProductListProps = {
    
}

const ProductList = (props: ProductListProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const saveChangesConfirmDialogRef = useRef<DialogRef>(null);
    const deleteItemConfirmDialogRef = useRef<DialogRef>(null);
    const { calculateExchange } = useCommon();
    const [selectedItem, setSelectedItem] = useState<FbaShipmentItemContract>();
    const [selectedProduct, setSelectedProduct] = useState<ProductContract>();

    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping],
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );

    const deleteItem = (item: FbaShipmentItemContract) => {
        setSelectedItem(item);
        deleteItemConfirmDialogRef.current?.show();
    }
    
    const fbaShipmentUpdateFormItems = useMemo(() => createFbaShipmentItemsForm(fbaShipment), [fbaShipment]);
    const checkStatus = (...status: FbaShipmentStatus[]) => fbaShipment ? status.includes(fbaShipment?.status) : false;

    const productColorCodes = useMemo(() => _.chain(fbaShipment?.items ?? []).map((item, i) => [item.product.productId, PackageItemColors[i % PackageItemColors.length]]).uniqWith(_.isEqual).fromPairs().value(), [fbaShipment?.items]);
    const canEdit = !!fbaShipment && (checkStatus("productPending", "fnskuPending"));
    const canEditFnsku = !!fbaShipment && (checkStatus("productPending", "fnskuPending", "ready", "processing"));
    const canEditExpireDate = !!fbaShipment && (checkStatus("productPending", "fnskuPending", "ready", "processing", "boxLabelPending", "paymentWaiting"));

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: fbaShipmentUpdateFormItems,
        validationSchema: Yup.array<FbaShipmentUpdateFormItem>(Yup.object<FbaShipmentUpdateFormItem, TypedShape<FbaShipmentUpdateFormItem>>({
            productId: Yup.string().required(),
            count: Yup.number().required(t("Count is required")),
            fnsku: Yup.string().notRequired(),
            status: Yup.string<FbaShipmentItemStatus>().notRequired(),
            expireDate: Yup.date().notRequired(),
            prepServices: Yup.array(Yup.string().required()).required()
        })),
        onSubmit: async values => {
            await dispatch(updateFbaShipmentItems({
                fbaShipmentId: fbaShipment!.fbaShipmentId,
                items: values
            }));

            saveChangesConfirmDialogRef.current?.hide();
        }
    });

    const saveFnskuLabelMutation = useMutation({
        mutationFn: async (index: number) => {
            const item = validation.values[index];

            await dispatch(updateFbaShipmentItems({
                fbaShipmentId: fbaShipment!.fbaShipmentId,
                items: [item]
            }));
        },
        onSuccess: (data, variables, context) => {
            validation.setFieldTouched(`${variables}.fnsku`, false, false);
        }
    });

    const totalPrice = useMemo(() => fbaShipment && _.sumBy(validation.values.map((item, index) => ({ 
        price: fbaShipment.items[index] ? calculateExchange(fbaShipment.items[index].buyPrice, fbaShipment.items[index].product.options.unitOfCurrency, fbaShipment.warehouse.settings.unitOfCurrency) : 0, 
        count: item.count 
    })), i => i.price * (i.count ?? 0)), [fbaShipment, validation.values]);

    const totalWeight = useMemo(() => fbaShipment && _.sumBy(validation.values.map((item, index) => ({ 
        weight: fbaShipment.items[index] ? convertWeight(fbaShipment.items[index].product.weight, fbaShipment.items[index].product.options.unitOfWeight, fbaShipment.warehouse.settings.unitOfWeight) : 0, 
        count: item.count 
    })), i => i.weight * (i.count ?? 0)), [fbaShipment, validation.values]);

    if (!fbaShipment) {
        return <Loader height="150px" />;
    }

    return <>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit} onReset={validation.handleReset}>
            <Card className="mt-2">
                <CardHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h5" className="mb-0">
                            {t("Selected Products ({{skuCount}} SKU & {{count}} items)", { 
                                skuCount: fbaShipment.items.length, 
                                count: _.sumBy(fbaShipment.items, i => i.count)
                            })}
                        </CardTitle>
                        {canEdit && <AddProductForm />}
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive table-card">
                        <Table className="mb-0 align-middle" size="sm" borderless>
                            <thead className="table-light text-muted">
                                <tr>
                                    <th></th>
                                    <th>{t("Image")}</th>
                                    <th style={{ width: "30%" }}>{t("Name")}</th>
                                    <th style={{ width: "10%" }}>{t("ASIN")} &amp; {t("FNSKU")}</th>
                                    <th>{t("Count")}</th>
                                    <th>{t("Status")}</th>
                                    <th>{t("Dimensions")}</th>
                                    <th>{t("Weight")}</th>
                                    <th>{t("Buy Price")}</th>
                                    <th>{t("Total")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading.load && <tr>
                                    <td colSpan={10}>
                                        <Loader height="100px" />
                                    </td>
                                </tr>}
                                {!loading.load && fbaShipment.items.map((shipmentItem, index) => <React.Fragment key={index}>
                                    <tr>
                                        <td className="align-middle" rowSpan={2}>
                                            <Button color="ghost-danger" type="button" className="btn-icon" size="sm" disabled={!canEdit || validation.values.length <= 1} onClick={() => deleteItem(shipmentItem)}>
                                                <i className="ri-delete-bin-line fs-16"></i>
                                            </Button>
                                        </td>
                                        <td>
                                            <ProductImageDisplay product={shipmentItem.product} />
                                        </td>
                                        <td>
                                            <Button color="link" className="text-start" onClick={e => {
                                                setSelectedProduct(shipmentItem.product);
                                            }}>
                                                <TruncatableText maxLines={2}>
                                                    {shipmentItem.product.name}
                                                </TruncatableText>
                                            </Button>
                                            {shipmentItem.isBundle && shipmentItem.bundleItems.length > 0 && <div className="d-flex align-items-center gap-1">
                                                <i className="ri-spam-fill text-warning"></i>
                                                <span className="fs-10">{t("Bundling from {{products}}", {
                                                    products: commaSeperated(shipmentItem.bundleItems.map(p => p.product.asin ?? "???"))
                                                })}</span> 
                                            </div>}
                                            {shipmentItem.isSplit && shipmentItem.splitItem && <div className="d-flex align-items-center gap-1">
                                                <i className="ri-spam-fill text-warning"></i>
                                                <span className="fs-10">{t("Splitting from {{product}}", {
                                                    product: shipmentItem.splitItem.product.asin ?? "???"
                                                })}</span> 
                                            </div>}
                                        </td>
                                        <td>
                                            <div className="vstack gap-1">
                                                {shipmentItem.product.asin && <Badge color="dark-subtle" className="text-body user-select-all">
                                                    {shipmentItem.product.asin}
                                                </Badge>}
                                                <BusyOverlay busy={saveFnskuLabelMutation.isPending && saveFnskuLabelMutation.variables === index} size="sm">
                                                    <ValidationWrapper validation={validation} field={`${index}.fnsku`}>
                                                        <TextInput size="sm" disabled={!canEditFnsku} placeholder={t("FNSKU")} maxLength={25}
                                                            onBlur={e => {
                                                                if (e.target.value === shipmentItem.fnsku) {
                                                                    return;
                                                                }

                                                                saveFnskuLabelMutation.mutate(index)
                                                            }}
                                                            onKeyDown={inputKeyEventHandler("Enter", e => {
                                                                if ((e.target as HTMLInputElement).value === shipmentItem.fnsku) {
                                                                    return;
                                                                }

                                                                saveFnskuLabelMutation.mutate(index);
                                                            })} />
                                                    </ValidationWrapper>
                                                </BusyOverlay>
                                            </div>
                                        </td>
                                        <td>
                                            <ValidationWrapper validation={validation} field={`${index}.count`}>
                                                <StepInput size="sm" min={1} disabled={!canEdit} style={{ width: "6rem" }} />
                                            </ValidationWrapper>
                                        </td>
                                        <td>
                                            <FbaShipmentItemReceivingStatusDisplay mini shipmentItem={shipmentItem} />
                                        </td>
                                        <td>
                                            <div>
                                                <Dimensions 
                                                    value={convertDimensions(shipmentItem.product, shipmentItem.product.options.unitOfLength, fbaShipment.warehouse.settings.unitOfLength)} 
                                                    unit={fbaShipment.warehouse.settings.unitOfLength}
                                                    showAlternative />
                                            </div>
                                        </td>
                                        <td>
                                            
                                            <div>
                                                <Weight 
                                                    value={convertWeight(shipmentItem.product.weight, shipmentItem.product.options.unitOfWeight, fbaShipment.warehouse.settings.unitOfWeight)} 
                                                    unit={fbaShipment.warehouse.settings.unitOfWeight}
                                                    showAlternative />
                                            </div>
                                        </td>
                                        <td>
                                            <Currency 
                                                value={calculateExchange(shipmentItem.buyPrice, shipmentItem.product.options.unitOfCurrency, fbaShipment.warehouse.settings.unitOfCurrency)} 
                                                currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                                        </td>
                                        <td>
                                            <Currency 
                                                value={calculateExchange(shipmentItem.buyPrice * (validation.values[index]?.count ?? 0), shipmentItem.product.options.unitOfCurrency, fbaShipment.warehouse.settings.unitOfCurrency)} 
                                                currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td className="p-1">
                                            <FbaShipmentItemStatusBadge value={shipmentItem.status} />
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            <ItemAvatar size={1} color={productColorCodes[shipmentItem.product.productId]} />
                                        </td>
                                        <td colSpan={6}>
                                            <ValidationWrapper validation={validation} field={`${index}.prepServices`}>
                                                <PrepServiceSelect checkboxes productOptionsOnly 
                                                    disabledCallback={val => val.serviceType === "expireDate" ? !canEditExpireDate : !canEdit}
                                                    isMulti channel="fba" warehouseId={fbaShipment.warehouse.warehouseId} />
                                            </ValidationWrapper>
                                        </td>
                                    </tr>
                                </React.Fragment>)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={4} className="text-end py-2">
                                        {t("Total")}
                                    </td>
                                    <th>
                                        {_.sumBy(validation.values, p => p.count ?? 0)}
                                    </th>
                                    <th colSpan={2}></th>
                                    <th>
                                        <Weight value={totalWeight} unit={fbaShipment.warehouse.settings.unitOfWeight} />
                                    </th>
                                    <th></th>
                                    <th>
                                        <Currency value={totalPrice} currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                                    </th>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </CardBody>
                {(canEdit || canEditExpireDate) && <CardFooter>
                    <div className="d-flex justify-content-end">
                        <BusyOverlay busy={loading.save} size="sm">
                            <ValidatorButton type="button" color="success" onClick={() => saveChangesConfirmDialogRef.current?.show()} validation={validation}>
                                {t("Save Changes")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </div>
                </CardFooter>}
            </Card>
        </Form>
        <Modal backdrop="static" isOpen={!!selectedProduct} toggle={() => setSelectedProduct(undefined)} size="xl">
            <ModalCloseButton onClick={() => setSelectedProduct(undefined)} />
            <ModalBody className="p-0">
                <ProductEditForm product={selectedProduct} />
            </ModalBody>
        </Modal>
        <Dialog ref={saveChangesConfirmDialogRef} color="success" buttons={["yes", "no"]} busy={loading.save} iconClass="ri-a-b"  
            message={t('paragraphs:FbaShipmentProductChangesConfirmation')} title={`Are you sure?`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    validation.submitForm();
                }
                else {
                    hide();
                }
            }} />
        <Dialog ref={deleteItemConfirmDialogRef} color="danger" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={t('The product will be deleted')} title={`Are you sure?`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(removeFbaShipmentItem({
                        fbaShipmentId: fbaShipment.fbaShipmentId,
                        fbaShipmentItemId: selectedItem!.fbaShipmentItemId
                    })).then(() => {
                        hide();
                        setSelectedItem(undefined);
                    });
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default ProductList;
import { useMutation, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import Restricted from "Components/Common/Restricted";
import TableContainer, { TableContainerRef, selectRowColumn } from "Components/Common/TableContainer";
import PrepServicePricingTypeBadge from "Components/Displays/PrepServicePricingTypeBadge";
import Currency from "Components/Displays/UnitDisplay/Currency";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import PrepServiceChannelSelect from "Components/EnumSelects/PrepServiceChannelSelect";
import SearchBox from "Components/Form/SearchBox";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { deletePrepService, getPrepServiceList } from "api/prepService";
import { PrepServiceContract } from "api/types/contracts/shipping";
import { ListPrepServicesQuery } from "api/types/queries";
import { Ref, RefAttributes, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Badge, Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useDebounceValue } from "usehooks-ts";

export type PrepServiceListRef = {
    reload: VoidFunction
}

const List = (props: {}, ref: Ref<PrepServiceListRef>) => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [selectedItems, setSelectedItems] = useState<PrepServiceContract[]>([]);
    const prepService = useMemo(() => selectedItems[0], [selectedItems]);
    
    const [query, setQuery] = useDebounceValue<ListPrepServicesQuery>({
        page: 1,
        pageSize: 10,
    }, 500);

    const { data: list, isFetching: loading, refetch: reload } = useQuery({
        queryKey: ["prep-service-list", query],
        queryFn: () => getPrepServiceList(query)
    });

    useEffect(() => {
        tableRef.current?.resetSelection();
    }, [list]);

    setPageTitle(`${t("Prep Service List")} - ${t("Management")}`);

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                reload();
                return Promise.resolve();
            }
        };
    }, [reload]);

    const deletePrepServiceMutation = useMutation({
        mutationFn: deletePrepService,
        mutationKey: ["delete-prep-service"],
        onSuccess: () => {
            setSelectedItems([]);
            setDeleteMode(undefined);
            dialogRef.current?.hide();
            reload();
        }
    });

    const batchDeletePrepServiceMutation = useMutation({
        mutationFn: async () => {
            for (const element of selectedItems) {
                await deletePrepService({
                    prepServiceId: element.prepServiceId
                });
            }
        },
        mutationKey: ["batch-delete-prep-service"],
        onSuccess: () => {
            setSelectedItems([]);
            setDeleteMode(undefined);
            dialogRef.current?.hide();
            reload();
        }
    });

    const handleDeleteClick = (arg: PrepServiceContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
        dialogRef.current?.show();
    };

    const handleMultiDeleteClick = () => {
        setDeleteMode("multi");
        dialogRef.current?.show();
    };

    // Column
    const columns = useMemo<ColumnDef<PrepServiceContract, any>[]>(() => [
        selectRowColumn<PrepServiceContract>(), 
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => <>
                <div className="hstack gap-1">
                    <Restricted require="management.prepservice" write ownership={cell.row.original} fallback={() => <Button color="ghost-dark" size="sm" className="btn-icon" disabled>
                            <i className="ri-pencil-fill fs-16"></i>
                        </Button>}>
                        <Link to={`/prep-services/edit/${cell.row.original.prepServiceId}`} className="btn btn-sm btn-icon btn-ghost-dark">
                            <i className="ri-pencil-fill fs-16"></i>
                        </Link>
                    </Restricted>
                    <Restricted require="management.prepservice" delete ownership={cell.row.original} fallback={() => <Button color="ghost-dark" size="sm" className="btn-icon" disabled>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>}>
                        <Button color="ghost-danger" size="sm" className="btn-icon"
                            onClick={() => handleDeleteClick(cell.row.original)}>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>
                    </Restricted>
                </div>
            </>
        }, 
        {
            header: t("SERVICE NAME"),
            accessorFn: item => item.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <Restricted require="management.prepservice" ownership={cell.row.original} write fallback={() => <>{cell.row.original.name}</>}>
                <Link to={`/prep-services/edit/${cell.row.original.prepServiceId}`} className="fw-medium link-secondary">{cell.row.original.name}</Link>
            </Restricted>
        },
        {
            header: t("WAREHOUSE"),
            accessorFn: item => item.warehouse.name,
            enableHiding: false,
            enableColumnFilter: false
        },
        {
            header: t("USED CHANNEL(S)"),
            accessorFn: item => item.channels,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <div className="hstack gap-1">
                {cell.row.original.channels.map((c, i) => <Badge key={i}>{t(`enums:PrepServiceChannel.${c}`)}</Badge>)}
            </div>
        },
        {
            header: t("UNIT PRICE"),
            accessorFn: item => item.price,
            enableColumnFilter: false,
            cell: (cell) => cell.row.original.priceRanges.length !== 0 ? <>
                <ListSummaryDisplay items={cell.row.original.priceRanges} displayFn={pr => <>{<Currency maxDigits={5} value={pr.from} currency={cell.row.original.warehouse.settings.unitOfCurrency} />} - {<Currency maxDigits={5} value={pr.to} currency={cell.row.original.warehouse.settings.unitOfCurrency} />} (<Currency maxDigits={5} value={pr.value} currency={cell.row.original.warehouse.settings.unitOfCurrency} />)</>} displayCount={1} />
            </> : <Currency value={cell.row.original.price} currency={cell.row.original.warehouse.settings.unitOfCurrency} />
        },
        {
            header: t("PRICING TYPE"),
            accessorFn: item => item.pricingType,
            enableColumnFilter: false,
            cell: (cell) => <PrepServicePricingTypeBadge value={cell.row.original.pricingType} />
        }],
        [t]
    );

    return <>
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Prep Services")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-1 flex-wrap">
                                    <Link to="/prep-services/new" className="btn btn-info add-btn">
                                        <i className="ri-add-line align-bottom me-1"></i> {t("Add New Prep Service")}
                                    </Link>
                                    {selectedItems.length > 0 && deleteMode !== "single" && <Button color="soft-danger" onClick={() => handleMultiDeleteClick()}>
                                        <i className="ri-delete-bin-2-line"></i>
                                    </Button>}
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <div>
                            <Row>
                                <Restricted require="management.company" read>
                                    <Col sm={6} md={3}>
                                        <CompanySelect value={query.companyId} onSelect={company => setQuery({
                                            ...query,
                                            companyId: company?.companyId
                                        })} />
                                    </Col>
                                </Restricted>
                                <Col sm={6} md={3}>
                                    <WarehouseSelect value={query.warehouseId} showClear
                                        onChange={val => setQuery({
                                            ...query,
                                            warehouseId: val
                                        })} ></WarehouseSelect>
                                </Col>
                                <Col sm={6} md={2}>
                                    <PrepServiceChannelSelect value={query.channel} showClear
                                        onChange={val => setQuery({
                                            ...query,
                                            channel: val
                                        })} ></PrepServiceChannelSelect>
                                </Col>
                                <Col sm={6} md={4}>
                                    <SearchBox value={query.search || ""} placeholder="Search name"
                                        onChange={val => setQuery({
                                            ...query,
                                            search: val
                                        })}></SearchBox>
                                </Col>
                            </Row>
                            <TableContainer
                                ref={tableRef}
                                busy={loading}
                                columns={columns}
                                nowrap
                                data={(list?.items || [])}
                                totalDataLength={list?.totalCount}
                                pagination={{
                                    pageIndex: query.page - 1,
                                    pageSize: query.pageSize
                                }}
                                onPaginationChanged={pagination => setQuery({
                                    ...query,
                                    page: pagination.pageIndex + 1,
                                    pageSize: pagination.pageSize
                                })}
                                onSelectionChanged={selection => {
                                    setSelectedItems(selection);
                                }}
                                divClass="mb-1"
                                tableClass="align-middle"
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={batchDeletePrepServiceMutation.isPending || deletePrepServiceMutation.isPending} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`}  title={t("Deleting prep service '{{name}}'", { name: prepService?.name, count: selectedItems.length})}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    if (deleteMode === "multi") {
                        batchDeletePrepServiceMutation.mutate();
                    }
                    else if (deleteMode === "single" && prepService) {
                        deletePrepServiceMutation.mutate({
                            prepServiceId: prepService.prepServiceId
                        });
                    }
                    else {
                        hide();
                        setSelectedItems([]);
                    }
                }
                else {
                    hide();
                    if (deleteMode === "single") {
                        setSelectedItems([]);
                    }
                }
            }} />
    </>;
}

export default forwardRef(List) as (
    props: RefAttributes<PrepServiceListRef>
) => ReturnType<typeof List>;
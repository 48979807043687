import BusyOverlay from "Components/Common/BusyOverlay";
import TextInput from "Components/Form/TextInput";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { BinContract } from "api/types/contracts/bin";
import { BinLocationContract } from "api/types/contracts/locations";
import classNames from "classnames";
import { inputKeyEventHandler } from "helpers/componentHelpers";
import { locationOf } from "helpers/locationOf";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { getLocationByLabel } from "slices/inventory/thunk";

type LocationSelectProps = {
    warehouseId: string | undefined,
    onSelect: (location: BinContract | BinLocationContract | undefined) => void;
};

const LocationSelect = (props: LocationSelectProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState<string>();
    const [putawayLocation, setPutawayLocation] = useState<BinContract | BinLocationContract>();
    const [notFound, setNotFound] = useState<false>();

    const doSearch = async () => {
        if (search) {
            setNotFound(undefined);
            const location = await dispatch(getLocationByLabel({ warehouseId: props.warehouseId!, label: search }));

            if (location) {
                setPutawayLocation(location);
            }
            else {
                setPutawayLocation(undefined);
                setSearch("");
                setNotFound(false);
            }
        }
        else {
            setPutawayLocation(undefined);
            setNotFound(undefined);
        }
    };

    const { loading } = useAppSelector(
        (state) => ({
            loading: state.Inventory.loading,
            error: state.Inventory.error,
        })
    );

    useEffect(() => {
        props.onSelect(putawayLocation);
    }, [putawayLocation]);
    
    const locationType = putawayLocation ? "binId" in putawayLocation ? t("Bin") : t("Bin Location") : "";

    return <Row>
            <Col xs={5}>
                <TextInput disabled={!props.warehouseId} value={search} onChange={setSearch} 
                    placeholder={t("{{bin}} or {{binLocation}}", { bin: "BIN-XXX", binLocation: "Area A1-1-1-1"})}
                    onKeyDown={inputKeyEventHandler("Enter", e => doSearch())}
                    onFocus={e => e.target.select()} />
            </Col>
            <Col xs={7}>
                <BusyOverlay busy={loading.location} size="sm">
                    <div className={classNames("hstack rounded border p-2 gap-2", {
                        "border-dark": !!putawayLocation
                    })}>
                        {putawayLocation ? <>
                            <i className="ri-price-tag-3-line text-secondary"></i>
                            <span>
                                {"binId" in putawayLocation ? `# ${putawayLocation.binCode}` : `# ${putawayLocation.name}`} {locationType}
                            </span>
                            <span className="text-success">
                                {t("Dest: {{destination}}", { destination: locationOf(putawayLocation) || "-" })}
                            </span>
                        </> : <>
                            {notFound === false ? <span className="text-danger">{t("Location not found")}</span>
                            : <span className="text-muted">{t("No location selected")}</span>}
                        </>}
                    </div>
                </BusyOverlay>
            </Col>
        </Row>;
};

export default LocationSelect;
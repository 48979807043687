import { InputHTMLAttributes, PropsWithChildren, useRef } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import classNames from 'classnames';
import { ValidatableInputProps } from "helpers/types";

export type RadioboxProps = ValidatableInputProps<boolean> & PropsWithChildren & Pick<InputHTMLAttributes<HTMLInputElement>, 'id' | 'disabled' | 'className' | 'style' | 'color'> & {
    label?: string
}

const Radiobox = (props: RadioboxProps) =>  {
    const { isValid, value, onChange, label, name, id, children, color, ...otherProps } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    return (<>
        <FormGroup check className={classNames(color ? `form-check-${color}` : "", {
            "d-flex gap-1": !!(label || children),
            "is-invalid": isValid === false
        })}>
            <Input
                innerRef={inputRef}
                valid={isValid && value} 
                invalid={isValid === false} 
                type="radio"
                onChange={e => onChange?.(e.target.checked)}
                checked={value || false}
                name={name}
                id={id || name}
                {...otherProps} />
                {(label || children) ? <Label htmlFor={id || name} check>{children || label}</Label> : null}
        </FormGroup>
    </>)
}

export default Radiobox;
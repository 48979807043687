import { useMutation } from "@tanstack/react-query";
import type { UpdateFbaShipmentCommand } from "api/types/commands";
import { PackageInfoContract } from "api/types/contracts/carrier";
import BusyOverlay from "Components/Common/BusyOverlay";
import Loader from "Components/Common/Loader";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import CarrierSelectTable from "Components/Shared/Shipment/CarrierSelectTable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import { updateFbaShipment } from "slices/shipping/fbaShipping/thunk";

const CarrierInfo = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { userProfile } = useProfile();
    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping], 
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );

    const boxes = useMemo(() => {
        if (!fbaShipment) {
            return [];
        }

        if (fbaShipment.packages.length === 0) {
            return fbaShipment.estimatedBoxes.map<PackageInfoContract>(p => ({
                height: p.height,
                length: p.length,
                width: p.width,
                weight: p.weight,
                desi: p.desi,
                lengthUnit: p.lengthUnit,
                weightUnit: p.weightUnit,
                currency: fbaShipment.warehouse.settings.unitOfCurrency ?? "usd",
                value: 0,
                items: [],
                shipmentTargetId: fbaShipment.shipmentTarget.shipmentTargetId,
                identifier: `Package ${p.boxNumber}`
            }));
        }
        else {
            return fbaShipment.packages.map<PackageInfoContract>(p => ({
                height: p.box.height ?? 0,
                length: p.box.length ?? 0,
                width: p.box.width ?? 0,
                weight: p.box.weight ?? 0,
                desi: undefined,
                lengthUnit: p.box.lengthUnit ?? fbaShipment.warehouse.settings.unitOfLength,
                weightUnit: p.box.weightUnit ?? fbaShipment.warehouse.settings.unitOfWeight,
                currency: fbaShipment.warehouse.settings.unitOfCurrency ?? "usd",
                value: p.value ?? 0,
                items: p.box.contents.map(p => ({
                    productId: p.product.productId,
                    quantity: p.sellableQuantity + p.unusableQuantity
                })),
                shipmentTargetId: p.shipmentTargetId ?? fbaShipment.shipmentTarget.shipmentTargetId,
                identifier: p.box.boxNumber
            }));
        }
    }, [fbaShipment]);

    const updateMutation = useMutation({
        mutationFn: (data: UpdateFbaShipmentCommand) => dispatch(updateFbaShipment(data))
    })

    if (!fbaShipment) {
        return <Loader height="250px" />;
    }

    const staticMode = fbaShipment.owner.userId !== userProfile?.user.userId || ["paymentWaiting", "paymentCompleted", "shipped"].includes(fbaShipment.status);
    const disabled = fbaShipment.owner.userId !== userProfile?.user.userId || ["paymentWaiting", "paymentCompleted", "shipped"].includes(fbaShipment.status);
    const canUseCustomShipmentLabel = fbaShipment.warehouse.settings.allowCustomShippingLabels 
        && (!fbaShipment.warehouse.settings.limitCustomShippingLabelsToDomesticOnly 
            || fbaShipment.warehouse.address.countryCode === fbaShipment.shipmentTarget?.countryCode);
    
    return <>
        <BusyOverlay busy={updateMutation.isPending} spinnerColor="tenant-primary" backgroundColor="light">
            <Card>
                <CardBody>
                    <div className="table-responsive table-card">
                        <CarrierSelectTable 
                            allowEmpty={canUseCustomShipmentLabel}
                            emptyOption={t("I will provide my own shipping label")}
                            value={fbaShipment.carrierAccountService?.carrierAccountServiceId ?? "00000000-0000-0000-0000-000000000000"} 
                            shipmentTargetId={fbaShipment.shipmentTarget.shipmentTargetId} 
                            originWarehouseId={fbaShipment.warehouse.warehouseId}
                            boxes={boxes} 
                            disableAutoRates={disabled}
                            disabled={disabled}
                            static={staticMode}
                            originCountryCode={fbaShipment.warehouse.address.countryCode}
                            initialRates={fbaShipment.carrierAccountService ? {
                                [fbaShipment.carrierAccountService.carrierAccountServiceId]: fbaShipment.shipmentCharges
                            } : {}}
                            onSelect={val => {
                                updateMutation.mutate({
                                    carrierAccountServiceId: val.carrierAccountServiceId,
                                    fbaShipmentId: fbaShipment.fbaShipmentId,
                                    actualCost: {
                                        ...fbaShipment.actualCost,
                                        shipping: val.rates?.shippingCost ?? 0,
                                        import: val.rates?.importTaxCost ?? 0,
                                        insurance: val.rates?.insuranceCost ?? 0,
                                        oversize: val.rates?.oversizeCost ?? 0,
                                        overweight: val.rates?.overweightCost ?? 0,
                                    },
                                    shipmentCharges: {
                                        totalCost: val.rates?.totalCost ?? 0,
                                        shippingCost: val.rates?.shippingCost ?? 0,
                                        importTaxCost: val.rates?.importTaxCost ?? 0,
                                        insuranceCost: val.rates?.insuranceCost ?? 0,
                                        oversizeCost: val.rates?.oversizeCost ?? 0,
                                        overweightCost: val.rates?.overweightCost ?? 0,
                                        details: val.rates?.details ?? {}
                                    }
                                });
                            }} />
                    </div>
                </CardBody>
            </Card>
        </BusyOverlay>
    </>;
}

export default CarrierInfo;
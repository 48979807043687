import type { FbaShipmentItemContract } from "api/types/contracts/shipping";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Progress } from "reactstrap";

type FbaShipmentItemReceivingStatusDisplayProps = {
    shipmentItem: Pick<FbaShipmentItemContract, "count" | "accepted" | "rejected">,
    mini?: boolean
}

const FbaShipmentItemReceivingStatusDisplay = (props: FbaShipmentItemReceivingStatusDisplayProps) => {
    const { t } = useTranslation();
    const receivedTotal = props.shipmentItem.accepted + props.shipmentItem.rejected;
    const unreceivedTotal = Math.max(props.shipmentItem.count - receivedTotal, 0);

    return <div className="vstack gap-2">
        <div className="hstack gap-2">
            <div className={classNames("text-danger", {
                "text-start": !props.mini,
                "text-center": props.mini
            })} style={{ flex: 1 }}>
                {!props.mini && <>
                    <span className="fs-11">{t("Rejected")}</span>
                    <br />
                </>}
                <span>{props.shipmentItem.rejected}</span>
            </div>
            <div className={classNames("text-success", {
                "text-start": !props.mini,
                "text-center": props.mini
            })} style={{ flex: 1 }}>
                {!props.mini && <>
                    <span className="fs-11">{t("Accepted")}</span>
                    <br />
                </>}
                <span>{props.shipmentItem.accepted}</span>
            </div>
            <div className={classNames("text-warning", {
                "text-start": !props.mini,
                "text-center": props.mini
            })} style={{ flex: 1 }}>
                {!props.mini && <>
                    <span className="fs-11">{t("Unreceived")}</span>
                    <br />
                </>}
                <span>{unreceivedTotal}</span>
            </div>
        </div>
        <Progress multi style={{ height: "0.3rem" }}>
            <Progress bar color="danger" value={props.shipmentItem.rejected} max={props.shipmentItem.count} />
            <Progress bar color="success" value={props.shipmentItem.accepted} max={props.shipmentItem.count} />
            <Progress bar color="warning" value={unreceivedTotal} max={props.shipmentItem.count} />
        </Progress>
    </div>;
}

export default FbaShipmentItemReceivingStatusDisplay;
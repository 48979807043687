import { useMutation, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { useDebounceValue } from "usehooks-ts";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Restricted from "Components/Common/Restricted";
import TableContainer, { TableContainerRef, selectRowColumn } from "Components/Common/TableContainer";
import TruncatableText from "Components/Common/TruncatableText";
import ProductIdentifiersDisplay, { ProductIdentifiersHeader } from "Components/Displays/ProductIdentifiersDisplay";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import ProductStockDisplay from "Components/Displays/ProductStockDisplay";
import Currency from "Components/Displays/UnitDisplay/Currency";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import Weight from "Components/Displays/UnitDisplay/Weight";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import CustomerSelect from "Components/EntitySelects/CustomerSelect";
import ProductBrandSelect from "Components/EntitySelects/ProductBrandSelect";
import ProductCategorySelect from "Components/EntitySelects/ProductCategorySelect";
import ProductSupplierSelect from "Components/EntitySelects/ProductSupplierSelect";
import UnitOfMeasurementSelect from "Components/EnumSelects/UnitOfMeasurementSelect";
import NumericRangeInput from "Components/Form/NumericRangeInput";
import SearchBox from "Components/Form/SearchBox";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { deleteProduct, getProductList } from "api/product";
import { UnitOfMeasurement } from "api/types/contracts/common";
import { CompanyContract } from "api/types/contracts/companies";
import { ProductContract } from "api/types/contracts/products";
import { ListProductsQuery } from "api/types/queries";
import { Ref, RefAttributes, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap";

export type ProductListRef = {
    reload: VoidFunction
}

const List = (props: {}, ref: Ref<ProductListRef>) => {
    const [company, setCompany] = useState<CompanyContract>();
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [selectedItems, setSelectedItems] = useState<ProductContract[]>([]);
    const [product, setProduct] = useState<ProductContract>();
    const { setPageTitle } = useMetadata();
    
    const [query, setQuery] = useDebounceValue<ListProductsQuery>({
        page: 1,
        pageSize: 10
    }, 500);

    const { t } = useTranslation();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);

    const { data: list, isFetching: loading, refetch: reload } = useQuery({
        queryKey: ["product-list", query],
        queryFn: () => getProductList(query)
    });

    useEffect(() => {
        tableRef.current?.resetSelection();
    }, [list]);

    setPageTitle(`${t("Product List")} - ${t("Management")}`);

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                reload();
            }
        };
    }, [reload]);

    const deleteProductMutation = useMutation({
        mutationFn: deleteProduct,
        mutationKey: ["delete-product"],
        onSuccess: () => {
            setSelectedItems([]);
            setDeleteMode(undefined);
            dialogRef.current?.hide();
            reload();
        }
    });

    const batchDeleteProductMutation = useMutation({
        mutationFn: async () => {
            for (const element of selectedItems) {
                await deleteProduct({
                    productId: element.productId
                });
            }
        },
        mutationKey: ["batch-delete-product"],
        onSuccess: () => {
            setSelectedItems([]);
            setDeleteMode(undefined);
            dialogRef.current?.hide();
            reload();
        }
    });

    const handleDeleteClick = (arg: ProductContract) => {
        setProduct(arg);
        setDeleteMode("single");
        dialogRef.current?.show();
    };

    const handleMultiDeleteClick = () => {
        setProduct(undefined);
        setDeleteMode("multi");
        dialogRef.current?.show();
    };

    // Column
    const columns = useMemo<ColumnDef<ProductContract, any>[]>(() => [
        selectRowColumn<ProductContract>(),
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return <>
                    <div className="hstack gap-1">
                        <Link to={`/products/edit/${cell.row.original.productId}`} className="btn btn-ghost-dark btn-sm btn-icon">
                            <i className="ri-pencil-fill fs-16"></i>
                        </Link>
                        <Button color="ghost-danger" className="btn-icon" size="sm"
                            onClick={() => handleDeleteClick(cell.row.original)}>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>
                    </div>
                </>;
            },
        }, {
            header: t("IMAGE"),
            accessorFn: item => item.options.image,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell) => <ProductImageDisplay largePreview product={cell.row.original} />,
        }, {
            header: t("NAME"),
            accessorFn: item => item.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => 
                <Link to={`/products/edit/${cell.row.original.productId}`}>
                    <TruncatableText maxLines={2}>
                        {cell.getValue()}
                    </TruncatableText>
                </Link>
        }, {
            id: "ASIN/UPC/EAN",
            header: (props) => <ProductIdentifiersHeader />,
            accessorFn: item => `${item.asin || "-"} / ${item.upc || "-"} / ${item.ean || "-"}`,
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell) => <ProductIdentifiersDisplay product={cell.row.original} />,
        }, {
            header: t("STOCK"),
            accessorFn: item => "-",
            enableColumnFilter: false,
            cell: (cell) => <ProductStockDisplay product={cell.row.original} />
        }, {
            header: t("SKU"),
            accessorFn: item => item.sku,
            enableColumnFilter: false,
        }, {
            header: t("SELL PRICE"),
            accessorFn: item => item.sellPrice,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.row.original.sellPrice} currency={cell.row.original.options.unitOfCurrency} />
        }, {
            header: t("BUY PRICE"),
            accessorFn: item => item.buyPrice,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.row.original.buyPrice} currency={cell.row.original.options.unitOfCurrency} />
        }, {
            header: t("DIMENSIONS"),
            cell: (cell) => {
                const item = cell.row.original;

                return <>
                    <Dimensions value={item} unit={item.options.unitOfLength} />
                    <br />
                    <Weight value={item.weight} unit={item.options.unitOfWeight}  />
                </>;
            }
        }, {
            header: t("UNIT"),
            accessorFn: item => item.unitOfMeasurement,
            enableColumnFilter: false,
            cell: (cell) => <>{t(`enums:UnitOfMeasurement.${cell.getValue<UnitOfMeasurement>()}`)}</>
        }, {
            header: t("BRAND"),
            accessorFn: item => item.brand?.name || "-",
            enableColumnFilter: false,
            cell: (cell) => <TruncatableText maxLines={2}>{cell.getValue()}</TruncatableText>
        }, {
            header: t("CATEGORY"),
            accessorFn: item => item.category?.name || "-",
            enableColumnFilter: false,
            cell: (cell) => <TruncatableText maxLines={2}>{cell.getValue()}</TruncatableText>
        }],
        [t]
    );

    return <>
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Product List")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-2 flex-wrap">
                                    {/* <UncontrolledDropdown>
                                        <DropdownToggle caret color="primary">
                                            {t("Actions")}
                                        </DropdownToggle>
                                    </UncontrolledDropdown> */}
                                    <Restricted require="management.inventory" create>
                                        <Link to="/products/new" className="btn btn-info add-btn">
                                            <i className="ri-add-line align-bottom me-1"></i> {t("Add Product")}
                                        </Link>
                                    </Restricted>
                                    {selectedItems.length > 0 && deleteMode !== "single" && <Button color="soft-danger" onClick={handleMultiDeleteClick}>
                                        <i className="ri-delete-bin-2-line"></i>
                                    </Button>}
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <Row className="mb-2">
                            <Restricted require="management.company" read>
                                <Col>
                                    <CompanySelect value={query.companyId} onSelect={company => {
                                        setCompany(company);
                                        setQuery({
                                            ...query,
                                            companyId: company?.companyId
                                        });
                                    }} />
                                </Col>
                            </Restricted>
                            <Restricted require="management.customer" read>
                                <Col>
                                    <CustomerSelect companyId={company?.companyId} value={query.customerId} onSelect={customer => {
                                        setQuery({
                                            ...query,
                                            customerId: customer?.customerId
                                        });
                                    }} />
                                </Col>
                            </Restricted>
                            <Col>
                                <ProductCategorySelect value={query.categoryId} onSelect={category => {
                                    setQuery({
                                        ...query,
                                        categoryId: category?.categoryId
                                    });
                                }} />
                            </Col>
                            <Col>
                                <ProductSupplierSelect value={query.supplierId} onSelect={supplier => {
                                    setQuery({
                                        ...query,
                                        supplierId: supplier?.supplierId
                                    });
                                }} />
                            </Col>
                            <Col>
                                <ProductBrandSelect value={query.brandId} onSelect={brand => {
                                    setQuery({
                                        ...query,
                                        brandId: brand?.brandId
                                    });
                                }} />
                            </Col>
                            <Col>
                                <UnitOfMeasurementSelect showClear value={query.unitofMeasurement} onChange={unit => {
                                    setQuery({
                                        ...query,
                                        unitofMeasurement: unit
                                    });
                                }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={4} lg={2} className="gap-2 hstack">
                                <Label className="mb-0 text-nowrap">{("Sell Price")}</Label>
                                <NumericRangeInput value={query.sellPriceRange} onChange={range => {
                                    setQuery({
                                        ...query,
                                        sellPriceRange: range
                                    })
                                }} />
                            </Col>
                            <Col sm={6} md={4} lg={2} className="gap-2 hstack">
                                <Label className="mb-0 text-nowrap">{("Buy Price")}</Label>
                                <NumericRangeInput value={query.buyPriceRange} onChange={range => {
                                    setQuery({
                                        ...query,
                                        buyPriceRange: range
                                    })
                                }} />
                            </Col>
                            <Col sm={6} md={4} lg={2} className="gap-2 hstack">
                                <Label className="mb-0 text-nowrap">{("Weight")}</Label>
                                <NumericRangeInput value={query.weightRange} onChange={range => {
                                    setQuery({
                                        ...query,
                                        weightRange: range
                                    })
                                }} />
                            </Col>
                            <Col sm={6} md={4} lg={6}>
                                <SearchBox value={query.search || ""} placeholder="Search by ASIN, SKU, UPC, EAN or Name & Description"
                                    onChange={val => setQuery({
                                        ...query,
                                        search: val
                                    })} />
                            </Col>
                        </Row>
                        <TableContainer
                            ref={tableRef}
                            busy={loading}
                            columns={columns}
                            data={(list?.items || [])}
                            totalDataLength={list?.totalCount}
                            pagination={{
                                pageIndex: query.page - 1,
                                pageSize: query.pageSize
                            }}
                            onPaginationChanged={pagination => setQuery({
                                ...query,
                                page: pagination.pageIndex + 1,
                                pageSize: pagination.pageSize
                            })}
                            onSelectionChanged={selection => {
                                setSelectedItems(selection);
                            }}
                            divClass="mb-1"
                            tableClass="align-middle"
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={batchDeleteProductMutation.isPending || deleteProductMutation.isPending} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting product '{{name}}'", { name: product?.name, count: deleteMode === "multi" ? selectedItems.length : 1 })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    if (deleteMode === "multi") {
                        batchDeleteProductMutation.mutate();
                    }
                    else if (deleteMode === "single" && product) {
                        deleteProductMutation.mutate({
                            productId: product.productId
                        });
                    }
                    else {
                        hide();
                        setSelectedItems([]);
                    }
                }
                else {
                    hide();
                    if (deleteMode === "single") {
                        setSelectedItems([]);
                    }
                }
            }} />
    </>;
}

export default forwardRef(List) as (
    props: RefAttributes<ProductListRef>
) => ReturnType<typeof List>;
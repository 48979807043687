import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import Restricted from "Components/Common/Restricted";
import TableContainer, { TableContainerRef } from "Components/Common/TableContainer";
import TruncatableText from "Components/Common/TruncatableText";
import ProductIdentifiersDisplay, { ProductIdentifiersHeader } from "Components/Displays/ProductIdentifiersDisplay";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import ProductStockDisplay from "Components/Displays/ProductStockDisplay";
import Currency from "Components/Displays/UnitDisplay/Currency";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import Weight from "Components/Displays/UnitDisplay/Weight";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import CustomerSelect from "Components/EntitySelects/CustomerSelect";
import ProductBrandSelect from "Components/EntitySelects/ProductBrandSelect";
import ProductCategorySelect from "Components/EntitySelects/ProductCategorySelect";
import ProductSupplierSelect from "Components/EntitySelects/ProductSupplierSelect";
import UnitOfMeasurementSelect from "Components/EnumSelects/UnitOfMeasurementSelect";
import Checkbox from "Components/Form/Checkbox";
import NumericRangeInput from "Components/Form/NumericRangeInput";
import SearchBox from "Components/Form/SearchBox";
import { getProductList } from "api/product";
import { UnitOfMeasurement } from "api/types/contracts/common";
import { CompanyContract } from "api/types/contracts/companies";
import { ProductContract } from "api/types/contracts/products";
import { ListProductsQuery } from "api/types/queries";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Label, Row } from "reactstrap";
import { useDebounceValue } from "usehooks-ts";

type ProductPickListProps = {
    disabledProducts?: (string | ProductContract)[],
    onSelectComplete: (products: ProductContract[]) => void,
    customerId?: string
};

const ProductPickList = (props: ProductPickListProps) => {
    const [company, setCompany] = useState<CompanyContract>();
    const [selectedItems, setSelectedItems] = useState<ProductContract[]>([]);
    
    const [query, setQuery] = useDebounceValue<ListProductsQuery>({
        page: 1,
        pageSize: 10,
        customerId: props.customerId
    }, 500);

    const { data: list, isFetching: loading, refetch: reload } = useQuery({
        queryKey: ["product-list", query],
        queryFn: () => getProductList(query)
    });

    const { t } = useTranslation();
    const tableRef = useRef<TableContainerRef>(null);
    
    useEffect(() => {
        tableRef.current?.resetSelection();
    }, [list]);

    const isRowDisabled = useCallback((row: ProductContract) => props.disabledProducts?.find(p => row.productId === (typeof p === "string" ? p : p.productId)) !== undefined,
        [props.disabledProducts]);

    // Column
    const columns = useMemo<ColumnDef<ProductContract, any>[]>(() => [
        {
            id: '#',
            enableHiding: false,
            header: (cell) => <Checkbox onChange={cell.table.toggleAllRowsSelected} indeterminate={cell.table.getIsSomeRowsSelected()} value={cell.table.getIsAllRowsSelected()} />,
            cell: (cell) => {
                return <Checkbox value={cell.row.getIsSelected()} onChange={cell.row.toggleSelected} disabled={isRowDisabled(cell.row.original)} />;
            }
        }, 
        {
            header: t("IMAGE"),
            accessorFn: item => item.options.image,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell) => <ProductImageDisplay product={cell.row.original} />,
        }, {
            header: t("NAME"),
            accessorFn: item => item.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => 
                <TruncatableText maxLines={3}>
                    {cell.getValue()}
                </TruncatableText>
        }, {
            id: "ASIN/UPC/EAN",
            header: (props) => <ProductIdentifiersHeader />,
            accessorFn: item => `${item.asin || "-"} / ${item.upc || "-"} / ${item.ean || "-"}`,
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell) => <ProductIdentifiersDisplay product={cell.row.original} />,
        }, {
            header: t("STOCK"),
            accessorFn: item => "-",
            enableColumnFilter: false,
            cell: (cell) => <ProductStockDisplay product={cell.row.original} />
        }, {
            header: t("SKU"),
            accessorFn: item => item.sku,
            enableColumnFilter: false,
        }, {
            header: t("SELL PRICE"),
            accessorFn: item => item.sellPrice,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.row.original.sellPrice} currency={cell.row.original.options.unitOfCurrency} />
        }, {
            header: t("BUY PRICE"),
            accessorFn: item => item.buyPrice,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.row.original.buyPrice} currency={cell.row.original.options.unitOfCurrency} />
        }, {
            header: t("DIMENSIONS"),
            cell: (cell) => {
                const item = cell.row.original;

                return <>
                    <Dimensions value={item} unit={item.options.unitOfLength} />
                    <br />
                    <Weight value={item.weight} unit={item.options.unitOfWeight}  />
                </>;
            }
        }, {
            header: t("UNIT"),
            accessorFn: item => item.unitOfMeasurement,
            enableColumnFilter: false,
            cell: (cell) => <>{t(`enums:UnitOfMeasurement.${cell.getValue<UnitOfMeasurement>()}`)}</>
        }, {
            header: t("BRAND"),
            accessorFn: item => item.brand?.name || "-",
            enableColumnFilter: false,
            cell: (cell) => <TruncatableText maxLines={2}>{cell.getValue()}</TruncatableText>
        }, {
            header: t("CATEGORY"),
            accessorFn: item => item.category?.name || "-",
            enableColumnFilter: false,
            cell: (cell) => <TruncatableText maxLines={2}>{cell.getValue()}</TruncatableText>
        }],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t]
    );

    return <>
        <Row className="g-2">
            <Col lg={12}>
                <Row className="mb-2 g-2">
                    <Restricted require="management.company" read>
                        <Col sm={6} md={4} lg={2}>
                            <CompanySelect value={query.companyId} onSelect={company => {
                                setCompany(company);
                                setQuery({
                                    ...query,
                                    companyId: company?.companyId
                                });
                            }} />
                        </Col>
                    </Restricted>
                    <Restricted require="management.customer" read>
                        <Col sm={6} md={4} lg={2}>
                            <CustomerSelect value={query.customerId} companyId={company?.companyId} onSelect={customer => {
                                setQuery({
                                    ...query,
                                    customerId: customer?.customerId
                                });
                            }} />
                        </Col>
                    </Restricted>
                    <Col sm={6} md={4} lg={3}>
                        <ProductCategorySelect value={query.categoryId} onSelect={category => {
                            setQuery({
                                ...query,
                                categoryId: category?.categoryId
                            });
                        }} />
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                        <ProductSupplierSelect value={query.supplierId} onSelect={supplier => {
                            setQuery({
                                ...query,
                                supplierId: supplier?.supplierId
                            });
                        }} />
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                        <ProductBrandSelect value={query.brandId} onSelect={brand => {
                            setQuery({
                                ...query,
                                brandId: brand?.brandId
                            });
                        }} />
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                        <UnitOfMeasurementSelect value={query.unitofMeasurement} onChange={unit => {
                            setQuery({
                                ...query,
                                unitofMeasurement: unit
                            });
                        }} />
                    </Col>
                </Row>
                <Row className="g-2">
                    <Col sm={6} md={4} lg={3} className="gap-2 hstack">
                        <Label className="mb-0 text-nowrap">{("Sell Price")}</Label>
                        <NumericRangeInput value={query.sellPriceRange} onChange={range => {
                            setQuery({
                                ...query,
                                sellPriceRange: range
                            })
                        }} />
                    </Col>
                    <Col sm={6} md={4} lg={3} className="gap-2 hstack">
                        <Label className="mb-0 text-nowrap">{("Buy Price")}</Label>
                        <NumericRangeInput value={query.buyPriceRange} onChange={range => {
                            setQuery({
                                ...query,
                                buyPriceRange: range
                            })
                        }} />
                    </Col>
                    <Col sm={6} md={4} lg={3} className="gap-2 hstack">
                        <Label className="mb-0 text-nowrap">{("Weight")}</Label>
                        <NumericRangeInput value={query.weightRange} onChange={range => {
                            setQuery({
                                ...query,
                                weightRange: range
                            })
                        }} />
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                        <SearchBox value={query.search || ""} placeholder="Search"
                            onChange={val => setQuery({
                                ...query,
                                search: val
                            })}></SearchBox>
                    </Col>
                </Row>
                <TableContainer
                    ref={tableRef}
                    busy={loading}
                    columns={columns}
                    data={(list?.items || [])}
                    totalDataLength={list?.totalCount}
                    pagination={{
                        pageIndex: query.page - 1,
                        pageSize: query.pageSize
                    }}
                    onPaginationChanged={pagination => setQuery({
                        ...query,
                        page: pagination.pageIndex + 1,
                        pageSize: pagination.pageSize
                    })}
                    onSelectionChanged={selection => {
                        setSelectedItems(selection);
                    }}
                    divClass="mb-1"
                    tableClass="align-middle"
                />
            </Col>
            <Col className="text-end">
                <Button color="primary" className="" onClick={() => {
                    props.onSelectComplete(selectedItems);
                }}>
                    {selectedItems.length > 0 ? t("Select {{count}} Product", { count: selectedItems.length }) : t("Back")}
                </Button>
            </Col>
        </Row>
    </>;
}

export default ProductPickList;